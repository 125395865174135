import React from "react";
import ourProcessImg3 from "../../assets/images/our-process-img3.png";

const WhatIsAES = () => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center background-linear-grad max-w-screen-xl gap-4 sm:gap-6 xl:gap-0 my-10">
      <div data-aos="fade-right" className="sm:w-[80%]">
        <img src={ourProcessImg3} alt="engg" />
      </div>
      <div className="sm:w-[90%] sm:p-6 mx-4">
        <h2
          data-aos="fade-left"
          className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
        >
          What is AES®?
        </h2>
        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          AES® is our trusted recruitment approach, powered by the latest
          executive search technology and hosted on our award-winning, fully
          digital platform. This proven methodology enables us to deliver
          outstanding results in a streamlined, highly effective recruitment
          process.
        </p>
      </div>
    </div>
  );
};

export default WhatIsAES;
