import React from "react";
import enggPageImg from "../../../assets/images/engPageImg2.webp";

const WhyChooseUs = () => {
  return (
    <div className="w-full my-4 flex flex-col justify-center items-center">
      <div className="relative flex mx-auto flex-col-reverse sm:flex-row px-4 my-4 sm:px-0 items-center justify-center w-full max-w-screen-2xl gap-4">
        <svg
          className="absolute -z-10 top-0"
          width="1440"
          height="861"
          viewBox="0 0 1440 861"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="1440"
            height="861"
            transform="matrix(-1 0 0 1 1440 0)"
            fill="url(#paint0_linear_171_179)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_171_179"
              x1="878.516"
              y1="-145"
              x2="688.357"
              y2="869.171"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2E388F" />
              <stop offset="0.208116" stop-color="white" />
              <stop offset="0.254801" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>

        <div className="flex flex-col sm:flex-row justify-between items-center max-w-screen-xl gap-4 sm:gap-6 xl:gap-0 my-10">
          <div className="sm:w-[80%] sm:p-6">
            <h2
              data-aos="fade-left"
              className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
            >
              Why Choose Alexander Executive Search as Your Engineering
              Recruitment Partner?
            </h2>
            <p
              data-aos="fade-left"
              data-aos-delay="100"
              className="text-sm sm:text-lg inter-regular mb-2 leading-5"
            >
              At Alexander Executive Search, we excel in engineering
              recruitment, understanding the intricate needs of both
              administrative and technical roles within the industry. Our
              specialized expertise allows us to identify and place top
              engineers who bring exceptional skills and value to our
              clients&#39; teams, driving their success and growth.
            </p>
          </div>

          <div data-aos="fade-right" className="w-full">
            <img src={enggPageImg} alt="engg" />
          </div>
        </div>
      </div>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 max-w-7xl">
        At Alexander Executive Search, we excel in engineering recruitment,
        understanding the intricate needs of both administrative and technical
        roles within the industry. Our specialized expertise allows us to
        identify Our engineering recruitment services are tailored to meet the
        unique demands of the sector, ensuring that we connect clients with
        highly qualified candidates who fit seamlessly into their organizational
        culture. In addition to sourcing top talent, our seasoned headhunters
        provide strategic advice on recruitment practices, including the
        alignment of compensation structures with industry standards.
      </p>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 max-w-7xl">
        Partner with Alexander Executive Search for your engineering recruitment
        needs and experience the benefits of working with a dedicated team that
        is committed to your success.
      </p>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 max-w-7xl">
        Our proven track record in engineering recruitment ensures that we
        deliver results that exceed expectations, positioning your organization
        for long-term success in a competitive market.and place top engineers
        who bring exceptional skills and value to our clients&#39; teams,
        driving their success and growth.
      </p>
    </div>
  );
};

export default WhyChooseUs;
