import React, { useContext, useEffect, useState } from 'react'
import BlogForm from './BlogForm'
import { BlogType } from '../../types/types'
import { AuthContext, AuthContextType, checkAuthStatus } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Blogs from '../Blogs/Blogs'


const CreateBlog = () => {
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [editBlogData, setEditBlogData] = useState({} as BlogType);

  const navigate = useNavigate()

  const checkStatus = async () => {
    const status = await checkAuthStatus();
    if (!status.status) {
      navigate('/login')
    };
  };

  useEffect(() => {
    (async () => {
      await checkStatus()
    })()
  }, []);


  const authContext = useContext(AuthContext) as AuthContextType

  useEffect(() => {
  }, [mode, authContext]);

  if (authContext === undefined) {
    return <div className='h-screen flex items-center justify-center'>Loading...</div>;
  }


  if (!authContext.isAuthenticated) {
    return <div className='h-screen flex items-center justify-center'>Loading...</div>;
  }

  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center w-full justify-center">
      {mode === 'edit' ? (
        <BlogForm mode="edit" initialValues={editBlogData} blogId={editBlogData._id} />
      ) :
        <BlogForm mode="create" />
      }
      <div className='w-full mx-auto max-w-screen-lg'>
        <Blogs isEditable setEditBlogData={setEditBlogData} setMode={setMode} />
      </div>
    </div>
  )
}

export default CreateBlog

