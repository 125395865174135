import { motion } from 'framer-motion';
import React from 'react';
import ourProcess from "../../assets/images/our-process-hero.webp"

const Hero = () => {
  return (
    <div className='relative mt-[64px] sm:mt-[72px] w-full snap-center h-[500px] pt-16 pb-24 flex content-center items-center justify-center'>
      <div
        className='absolute top-0 w-full h-full bg-center bg-cover'
        style={{
          backgroundImage: `url('${ourProcess}')`,
        }}
      >
        <motion.span
          initial={{ opacity: 0.8 }}
          animate={{ opacity: 0.2, }}
          transition={{ duration: 1 }}
          id='blackOverlay'
          className='w-full h-full absolute opacity-20 bg-black'
        ></motion.span>
      </div>
      <main>
        <div className='relative px-4 lg:px-8'>
          <div className='mx-auto max-w-5xl pt-6'>
            <div className='text-center'>
              <motion.h1 initial={{ opacity: 0, translateY: 40 }} animate={{ opacity: 1, translateY: 0 }} transition={{ duration: 0.5 }} className='text-2xl inter-regular decoration-solid text-white italic tracking-tight lg:text-6xl'>
                <span className='font-bold custom-gradient-2 italic'>
                  Our
                </span>{' '}
                Process
              </motion.h1>{' '}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Hero;
