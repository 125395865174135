import React from 'react';
import { Field } from 'formik';

interface FormikSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  className?: string;
  label?: string;
  options: Array<{ label: string; value: string | number }>;
}

const FormikFieldSelect = ({ name, label, options, className, ...props }: FormikSelectProps) => {
  return (
    <div className="mb-4 w-full">
      {label && (
        <label
          htmlFor={name}
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <Field
        as="select"
        id={name}
        name={name}
        className={`w-full sm:w-[400px] px-3 py-2.5 border bg-white rounded-lg border-gray-300 focus:ring-1 active:ring-0 focus:outline-none focus:ring-blue-500 ${className}`}
        {...props}
      >
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    </div>
  );
};

export default FormikFieldSelect;

