import React from 'react'
import execTalent from "../../assets/images/execTalent.webp"

const ExecTalent = () => {
  return (
    <div className='flex flex-col sm:flex-row justify-between border-[10px] border-white transition-all duration-300 hover:border-primary items-center background-linear-grad2 max-w-screen-xl gap-4 sm:gap-10 xl:gap-0 my-6 sm:my-20'>
      <div data-aos="fade-right" className='w-full'>
        <img src={execTalent} alt="engg" />
      </div>
      <div className='w-[90%]'>
        <h2 data-aos="fade-left" className='text-3xl sm:text-[42px] sm:leading-[50px] inter-regular mb-4 !font-medium text-primary'>Your Exclusive Talent Acquisition Partner</h2>
        <p data-aos="fade-left" data-aos-delay="100" className='text-sm sm:text-lg inter-regular mb-2 leading-5'>Recognizing the paramount importance of finding the perfect match for your organization, we offer a rigorous and award-winning recruitment process designed for enduring partnerships between candidates and companies. Our specialized expertise in the Civil Engineering sector ensures our ability to identify candidates who can make a significant impact from the outset, guiding your organization towards enduring success.</p>
      </div>
    </div>
  )
}

export default ExecTalent
