import React from 'react'
// Import react-circular-progressbar module and styles
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimateProgressProvider";
import RadialSeparators from "./RadicalSeparators";

type Props = {
  valueEnd: number
  title: string
}

const Progressbar = ({ valueEnd, title }: Props) => {
  return (
    <AnimatedProgressProvider
      valueStart={valueEnd < 30 ? 0 : 68}
      valueEnd={valueEnd}
      duration={1.4}
      easingFunction={easeQuadInOut}
    >
      {(value) => {
        const roundedValue = Math.round(value);
        return (
          <CircularProgressbarWithChildren
            value={value}
            strokeWidth={20}
            styles={buildStyles({
              strokeLinecap: "butt",
              trailColor: "#fff",
              pathColor: "#267DFF",
            })}
            text={`${roundedValue}%`}
          >
            <div className='relative'>
              <div className='absolute flex flex-col z-10 top-[15%] text-center left-[10%]'>
                <span className='text-3xl font-bold'>{roundedValue}%</span>
                <span className='text-sm'>{title}</span>
              </div>
              <svg className='relative z-0' xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 132 132" fill="none">
                <circle cx="66" cy="66" r="66" fill="url(#paint0_radial_9_3563)" />
                <defs>
                  <radialGradient id="paint0_radial_9_3563" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(66 66) rotate(90) scale(66)">
                    <stop stopColor="#267DFF" />
                    <stop offset="1" stopColor="#202379" />
                  </radialGradient>
                </defs>
              </svg>

            </div>
            <RadialSeparators
              count={70}
              style={{
                background: "#231E54",
                width: "4px",
                // This needs to be equal to props.strokeWidth
                height: `${20}%`,
              }}
            />
          </CircularProgressbarWithChildren>
        );
      }}
    </AnimatedProgressProvider>)
}

export default Progressbar
