import React from "react";
import civilEngg from "../../assets/images/civil-engg.webp";
import infra from "../../assets/images/infra.webp";
import engg from "../../assets/images/engg.webp";
import aboutUsImg from "../../assets/images/aboutUsImg.webp";
import sectionDesign from "../../assets/images/section-design.webp";
import { useNavigate } from "react-router-dom";

type SectionProp = {
  image: string;
  text: string;
  link: string;
};

const sections: SectionProp[] = [
  {
    image: civilEngg,
    text: "Civil Engineering",
    link: "civil-engineering",
  },
  {
    image: engg,
    text: "Engineering",
    link: "engineering",
  },
  {
    image: infra,
    text: "Infrastructure",
    link: "infrastructure",
  },
];

const SpecialistRec = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col justify-center items-center my-6 px-4 sm:mt-20 sm:mx-auto">
      <h1 className="text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary">
        SPECIALIST RECRUITMENT <br /> ACROSS THREE SECTORS:
      </h1>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-10 max-w-4xl">
        Welcome to Alexander Executive Search Limited, where we excel in
        empowering excellence in talent retention. As your premier destination
        for top-tier talent acquisition, we are a global team of experts
        dedicated to serving the Civil Engineering, Engineering, and the
        infrastructure sectors.
      </p>
      <div className="flex flex-col sm:flex-row relative justify-center items-center gap-4 sm:gap-8 my-4 sm:my-20 w-full">
        {sections.map((section, index) => (
          <div
            key={index}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(section.link);
            }}
            className="bg-primary cursor-pointer sm:h-[700px] max-w-[400px] [&>.img1]:hover:scale-125 [&>.img2]:hover:translate-y-10 [&>.text]:hover:translate-y-10 relative overflow-hidden"
          >
            <img
              className="img1 object-cover w-full sm:h-[400px] relative transition duration-300 z-10"
              src={section.image}
              alt="engg"
            />
            <img
              className="img2 -mt-16 w-full relative z-20 transition duration-300"
              src={sectionDesign}
              alt="engg"
            />
            <h3 className="text absolute text-2xl sm:text-4xl z-40 bottom-28 text-left w-full mx-10 text-white transition duration-300">
              {section.text}
              <br /> Recruitment
            </h3>
          </div>
        ))}
      </div>
      <hr className="w-[90%] border px-20 border-primary opacity-50 mt-6" />
      <div className="flex flex-col sm:flex-row border-[10px] border-white transition-all duration-300 hover:border-primary justify-between items-center background-linear-grad max-w-screen-xl gap-4 sm:gap-10 xl:gap-0 my-10 sm:my-20">
        <div data-aos="fade-right" className="w-full">
          <img src={aboutUsImg} alt="engg" />
        </div>
        <div className="sm:w-[90%]">
          <h2
            data-aos="fade-left"
            className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
          >
            About Us
          </h2>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            Our comprehensive recruitment expertise spans from mid-level
            management to C-suite executives, ensuring precise identification of
            exceptional candidates who not only possess the required skill sets
            but also exhibit the character that aligns seamlessly with each
            unique opportunity.
          </p>
          <p
            data-aos="fade-left"
            data-aos-delay="200"
            className="text-sm sm:text-lg inter-regular leading-5"
          >
            Partnering with us embarks you on a collaborative journey focused on
            introducing you to exceptional candidates capable of driving
            transformative success stories, redefining businesses and industries
            alike.
          </p>
        </div>
      </div>
      <hr className="w-[90%] border px-20 border-primary opacity-50" />
    </div>
  );
};

export default SpecialistRec;
