import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { BlogType } from "../../types/types";
import BlogList from "./BlogList";
import Pagination from "./Pagination";
import { Helmet } from "react-helmet";

type Props = {
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
};

const Blogs = ({ isEditable, setMode, setEditBlogData }: Props = {}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          Alexander Executive Search Blog | Insights on Civil Engineering
          Recruitment & Talent Strategies
        </title>
        <meta
          name="description"
          content="Stay updated with the latest in civil engineering recruitment trends, talent acquisition strategies, and industry insights. Explore Alexander Executive Search's expert tips and resources on hiring, retention, and market shifts tailored for the civil engineering sector."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <div className="mt-[64px] sm:mt-[72px] py-6 sm:py-10">
          <BlogList
            isEditable={isEditable}
            setMode={setMode}
            setEditBlogData={setEditBlogData}
            setBlogs={authContext.setBlogs}
            blogs={authContext.blogs}
          />
          <Pagination
            currentPage={authContext.currentPage}
            totalPages={authContext.totalPages}
            onPageChange={authContext.handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Blogs;
