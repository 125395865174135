import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { MdOutlineLocationCity } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import * as Yup from "yup";
import PrimaryButton from "../common/PrimaryButton";
import { ContactFormValues } from "../../types/types";
import { sendContactPageData } from "../../service/service";
import { toast } from "react-toastify";

const ContactForm = () => {
  const initialValues: ContactFormValues = {
    firstName: "",
    company: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Please select how you found us"),
  });

  const handleSubmit = async (values: ContactFormValues) => {
    const res = await sendContactPageData({
      from: "contact-home-page",
      ...values,
    });
    if (res.status === false) {
      toast.error("Something went wrong");
    } else {
      toast.success("Message sent successfully");
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-6xl font-bold mb-2">
        Get in <span className="text-primary">Touch</span>
      </h1>

      <p className="text-gray-600 mb-8">
        To get in touch with a recruitment expert please enter the below details
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className="space-y-6">
            <div>
              <label
                htmlFor="firstName"
                className="flex items-center text-gray-500 mb-1"
              >
                *First Name
              </label>
              <Field
                id="firstName"
                name="firstName"
                type="text"
                className={`w-full border-b ${errors.firstName && touched.firstName
                    ? "border-red-500"
                    : "border-gray-300"
                  } focus:border-indigo-600 focus:outline-none py-2`}
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div>
              <label
                htmlFor="company"
                className="flex items-center text-gray-500 mb-1"
              >
                *Company
              </label>
              <Field
                id="company"
                name="company"
                type="text"
                className={`w-full border-b ${errors.company && touched.company
                    ? "border-red-500"
                    : "border-gray-300"
                  } focus:border-indigo-600 focus:outline-none py-2`}
              />
              <ErrorMessage
                name="company"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="flex items-center text-gray-500 mb-1"
              >
                *Email Address
              </label>
              <Field
                id="email"
                name="email"
                type="email"
                className={`w-full border-b ${errors.email && touched.email
                    ? "border-red-500"
                    : "border-gray-300"
                  } focus:border-indigo-600 focus:outline-none py-2`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div>
              <label
                htmlFor="message"
                className="flex items-center text-gray-500 mb-1"
              >
                *How Did You Find Us
              </label>
              <Field
                as="select"
                id="message"
                name="message"
                className={`w-full border-b ${errors.message && touched.message
                    ? "border-red-500"
                    : "border-gray-300"
                  } focus:border-indigo-600 focus:outline-none py-2 bg-transparent`}
              >
                <option value="">Select an option</option>
                <option value="Search Engine">Search Engine</option>
                <option value="Referral">Referral</option>
                <option value="Social Media">Social Media</option>
              </Field>
              <ErrorMessage
                name="message"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <PrimaryButton type="submit" className="w-full">
              SEND
            </PrimaryButton>
          </Form>
        )}
      </Formik>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
        <div className="flex items-start space-x-4">
          <MdOutlinePhoneInTalk className="w-10 h-10" />
          <div className="text-gray-600">
            <h3 className="font-bold mb-2">PHONE</h3>
            <a
              href="tel:+442039834415"
              className="text-indigo-600 hover:text-indigo-700"
            >
              +44 (0)203 983 4415
            </a>{" "}
            <br />
            <a
              href="tel:+13326008926"
              className="text-indigo-600 hover:text-indigo-700"
            >
              +1 (332) 600 8926
            </a>
          </div>
        </div>

        <div className="flex items-start space-x-4">
          <MdOutlineLocationCity className="w-10 h-10" />
          <div className="text-gray-600">
            <h3 className="font-bold mb-2">LOCATION</h3>
            <p className="text-indigo-600">
              60 Cannon Street, London, EC4N 6P U.K
            </p>
            <p className="text-indigo-600 mt-2">
              641 Lexington Avenue New York, NY, 10022, U.S
            </p>
          </div>
        </div>

        <div className="flex items-start space-x-4">
          <MdOutlineMail className="w-10 h-10" />
          <div className="text-gray-600">
            <h3 className="font-bold mb-2">EMAIL</h3>
            <a
              href="mailto:info@aesrecruitment.com"
              className="text-indigo-600 hover:text-indigo-700"
            >
              info@aesrecruitment.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
