// src/pages/BlogDetailPage.tsx

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchBlogById } from "../../service/service";
import { BlogType } from "../../types/types";
import Blogs from "./Blogs";

const BlogDetailPage = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [blog, setBlog] = useState<BlogType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getBlog = async () => {
      try {
        setLoading(true);
        const data = await fetchBlogById(id as string);
        setBlog(data.response?.data as BlogType);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    if (location.state !== null) {
      setBlog(location?.state?.blog as BlogType);
    } else {
      getBlog();
    }
  }, [id, location, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blog) {
    return (
      <div className="w-full pt-28 mt-10 flex justify-center items-center">
        Blog not found.
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="pt-28 p-4 w-full max-w-screen-2xl">
        {blog.tags.map((tag: string) => (
          <div
            key={tag}
            className="inline-block bg-blue-600 rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2"
          >
            {tag}
          </div>
        ))}
        <h1 className="text-3xl mt-4 font-bold">{blog.title}</h1>
        <div className="flex gap-4 mt-4 items-center">
          <img
            className="w-8 h-8 rounded-full"
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
            alt="Avatar of Author"
          />
          <p className="font-bold">{blog.author}</p>
          <p>{format(blog.date, "MMMM dd, yyyy")}</p>
        </div>

        <div className="mt-4">
          <img
            width="100%"
            className="object-cover h-[500px]"
            src={blog.image as unknown as string}
            alt="Blog"
          />
        </div>
        <div
          className="mt-4 html-res"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></div>
      </div>
      <div className="mt-6">
        <Blogs />
      </div>
    </div>
  );
};

export default BlogDetailPage;
