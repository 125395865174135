import React, { ReactElement, createRef, RefObject } from "react";
import { Animate } from "react-move";

// Define the type for the component's props
interface AnimatedProgressProviderProps {
  valueStart?: number;
  valueEnd: number;
  duration: number;
  easingFunction: (t: number) => number;
  children: (value: number) => ReactElement; // Ensure children returns ReactElement
}

interface AnimatedProgressProviderState {
  isAnimated: boolean;
  isHovered: boolean;
}

class AnimatedProgressProvider extends React.Component<
  AnimatedProgressProviderProps,
  AnimatedProgressProviderState
> {
  interval: number | undefined;
  elementRef: RefObject<HTMLDivElement>; // Reference to the component for Intersection Observer

  // Initialize state with TypeScript syntax
  state: AnimatedProgressProviderState = {
    isAnimated: false,
    isHovered: false
  };

  // Define default props
  static defaultProps: Partial<AnimatedProgressProviderProps> = {
    valueStart: 0
  };

  constructor(props: AnimatedProgressProviderProps) {
    super(props);
    this.elementRef = createRef(); // Create a reference for the div
  }

  componentDidMount() {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.setState({ isAnimated: true });
            }
          });
        },
        { threshold: 0.5 } // Adjust threshold as needed (0.5 means half of the element is in view)
      );

      if (this.elementRef.current) {
        observer.observe(this.elementRef.current);
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true, isAnimated: true });

    // Set up repeating animation while hovered
    this.interval = window.setInterval(() => {
      this.setState((prevState) => ({
        isAnimated: !prevState.isAnimated
      }));
    }, this.props.duration * 1000);
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });

    // Stop repeating animation when no longer hovered
    if (this.interval) {
      window.clearInterval(this.interval);
    }

    // Ensure animation ends smoothly after hover
    this.setState({ isAnimated: true });
  };

  render() {
    return (
      <div
        ref={this.elementRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Animate
          start={() => ({
            value: this.props.valueStart
          })}
          update={() => ({
            value: this.state.isAnimated
              ? this.props.valueEnd
              : this.props.valueStart,
            timing: {
              duration: this.props.duration * 1000, // Ensure the duration is applied
              ease: this.props.easingFunction
            }
          })}
        >
          {({ value }) => this.props.children(value as number)}
        </Animate>
      </div>
    );
  }
}

export default AnimatedProgressProvider;

