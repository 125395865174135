import React from "react";
import { GoTriangleRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../common/PrimaryButton";
import Progressbar from "../Progressbar/Progressbar";

const Progress = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center text-white py-6 sm:py-20 px-4 sm:px-6 items-center bg-[#231E54]">
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row max-w-screen-xl">
        <div className="flex flex-col gap-2 sm:gap-3 max-w-[280px]">
          <div>
            <h2 data-aos="fade-right" className="text-3xl sm:text-4xl">
              We help <br /> businesses
            </h2>
            <h2
              data-aos="fade-right"
              data-aos-delay="100"
              className="text-3xl sm:text-4xl font-bold"
            >
              Find, Hire & <br /> RETAIN
            </h2>
          </div>
          <p
            data-aos="fade-right"
            data-aos-delay="200"
            className="text-base text-[#F7F7F7] poppins-regular"
          >
            Companies we partner with are retaining 96% of hires made with
            Alexander Executive Search.
          </p>
          <PrimaryButton
            onClick={() => {
              navigate("/contact-us");
              window.scrollTo(0, 0);
            }}
            data-aos="fade-right"
            data-aos-delay="300"
            className="max-w-[180px]"
          >
            Contact Us
          </PrimaryButton>
        </div>
        <div className="flex -my-28 sm:my-0 items-center rotate-90 md:rotate-0">
          <hr className="w-[1px] h-[300px] -mr-4 -mt-4 md:mt-0 bg-[#F7F7F7]" />
          <GoTriangleRight fontSize={40} className="text-[#267DFF]" />
        </div>
        <div className="flex flex-col sm:flex-row md:flex-wrap gap-2 sm:gap-4">
          <div className="flex flex-col justify-center gap-4 items-center max-w-[280px]">
            <div className="hover:scale-110 max-w-[200px] transition-all duration-300">
              <Progressbar title="of Roles filled" valueEnd={98} />
            </div>
            <p className="text-[#F7F7F7] poppins-regular text-center">
              More than 98% of the searches we undertake are fulfilled
            </p>
          </div>
          <div className="flex flex-col justify-center gap-4 items-center max-w-[280px]">
            <div className="hover:scale-110 max-w-[200px] transition-all duration-300">
              <Progressbar title="Retention rate" valueEnd={96} />
            </div>
            <p className="text-[#F7F7F7] poppins-regular text-center">
              Over 96% of candidates we place are still in situ after 12 months.
            </p>
          </div>
          <div className="flex flex-col justify-center gap-4 items-center max-w-[280px]">
            <div className="hover:scale-110 max-w-[200px] transition-all duration-300">
              <Progressbar title="Months replacement" valueEnd={24} />
            </div>
            <p className="text-[#F7F7F7] poppins-regular text-center">
              Industry leading replacement policy of up to 2 years.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
