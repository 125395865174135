import React from 'react'

const Section2 = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center my-6 px-4 sm:mt-20 sm:mx-auto'>
      <h1 className='text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary uppercase'>Cutting-Edge Recruitment Powered by AES®</h1>
      <p className='text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-10 max-w-7xl'>Tired of shifting through piles of resumes only to see new hires quit shortly after? Our AES® methodology transforms legacy recruitment approaches into a streamlined, scientific process for
        exceptional retention and ROI.</p>
      <p className='text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-6 max-w-7xl'>The proprietary AES® platform, honed over years of recruitment research, provides unparalleled
        candidate visibility while its predictive tools enable precision hiring. By identifying motivation
        profiles, competencies, and future performance predictors for each role, AES® reduces mis-hires and
        turbocharges building high-calibre, long-term teams</p>
    </div>
  )
}

export default Section2
