import React from 'react'
import ourProcessImg3 from '../../assets/images/our-process-img3.webp'

const Section4 = () => {
  return (
    <div className='flex flex-col w-full px-4'>
      <h2 className='text-3xl sm:text-5xl text-center text-primary inter-regular mb-6 sm:mb-10'>Transform Your Hiring</h2>
      <div className='flex flex-col sm:flex-row items-center gap-10 w-full mb-10 justify-between'>
        <div className='sm:w-[50%]'>
          <img src={ourProcessImg3} alt="construction" className='w-full' />
        </div>
        <div className='flex flex-col gap-4 sm:w-[50%]'>
          <div className='w-full shadow-md hover:bg-primary hover:text-white rounded-md transition-all duration-300 px-10 py-8'>
            Access to curated candidate shortlists 24/7 from any web enabled device
          </div>
          <div className='w-full shadow-md hover:bg-primary hover:text-white rounded-md transition-all duration-300 px-10 py-8'>
            On-demand video introductions and predictive candidate analytics
          </div>
          <div className='w-full shadow-md hover:bg-primary hover:text-white rounded-md transition-all duration-300 px-10 py-8'>
            A unified platform connecting your team with our experts
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section4
