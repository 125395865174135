import { toast } from "react-toastify";
import axiosClient from "../api/axiosClient";
import { BlogType, ContactFormValues, HomeContactFormValues } from "../types/types";

type LoginValues = {
  email: string;
  password: string;
};

type User = {
  id: string;
  email: string;
};

export const loginUser = async (
  values: LoginValues,
): Promise<{ loggedIn: boolean; user: User | null }> => {
  try {
    const response = await axiosClient.post("/auth/login", values);
    console.log("Login successful:");
    return { loggedIn: true, user: response.data.user };
  } catch (error: any) {
    const mes = error.response.data.message;
    toast.error(mes);
    console.error("Error:", error);
  }
  return { loggedIn: false, user: null };
};

export const meCall = async (): Promise<{
  user: {
    id: string;
    email: string;
  } | null;
  status: number;
}> => {
  try {
    const response = await axiosClient.get("/auth/me");
    return { user: response.data.user, status: response.status };
  } catch (error) {
    console.error("Error:", error);
  }
  return { user: null, status: 500 };
};

export const logoutUser = async (): Promise<{ loggedOut: boolean }> => {
  try {
    const response = await axiosClient.post("/auth/logout");
    console.log("Logout successful:");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
  return { loggedOut: false };
};

export const createJob = async (values: FormData) => {
  try {
    const response = await axiosClient.post("/jobs/create", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const getJobs = async () => {
  try {
    const response = await axiosClient.get("/jobs/get");
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const deleteJob = async (jobId: string) => {
  try {
    const response = await axiosClient.delete(`/jobs/delete/${jobId}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const deleteBlog = async (blogId: string) => {
  try {
    const response = await axiosClient.delete(`/blogs/delete/${blogId}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const editBlog = async (blogId: string, values: FormData) => {
  try {
    const response = await axiosClient.put(`/blogs/update/${blogId}`, values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const fetchBlogs = async (page: number, limit: number) => {
  try {
    const response = await axiosClient.get<{
      blogs: BlogType[];
      totalPages: number;
      message: string;
    }>(`/blogs/get?page=${page}&limit=${limit}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const fetchBlogById = async (id: string) => {
  try {
    const response = await axiosClient.get(`/blogs/get/${id}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const fetchJobById = async (id: string) => {
  try {
    const response = await axiosClient.get(`/jobs/get/${id}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const sendHomeContactPageData = async (values: HomeContactFormValues & { from: string }) => {
  try {
    const response = await axiosClient.post("/sendMail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
}


export const sendContactPageData = async (values: ContactFormValues & { from: string }) => {
  try {
    const response = await axiosClient.post("/sendMail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
}


// export const fetchBlogs = async (page: number, limit: number) => {
//   try {
//     // Calculate the start and end index for pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//
//     // Simulate a paginated response from mock data
//     const paginatedBlogs = blogs.slice(startIndex, endIndex);
//
//     // Simulate a successful response
//     return {
//       status: true,
//       response: {
//         data: paginatedBlogs,
//         currentPage: page,
//         totalPages: Math.ceil(blogs.length / limit),
//       },
//     };
//   } catch (error) {
//     console.error("Error:", error);
//     return { status: false, response: null };
//   }
// };
//
// export const fetchBlogById = async (id: string) => {
//   try {
//     // Simulate finding the blog by ID
//     const blog = blogs.find((blog) => blog._id === id);
//
//     // Simulate a successful response
//     return {
//
//       status: true,
//       response: {
//         data: blog,
//       },
//     };
//   } catch (error) {
//     console.error("Error:", error);
//     return { status: false, response: null };
//   }
// };
