import React from 'react'

type CardType = {
  title: string,
  description: string
}

const cards: CardType[] = [
  {
    title: "Industry-Leading Retention Rates of 96%",
    description: "Experience the peace of mind offered by our FREE replacement policy, extending up to 12 months. However, considering our remarkable success rate, the chances of needing it are quite unlikely."
  },
  {
    title: "Save Upwards of $100K per Hire",
    description: "By enhancing your retention rates and minimizing commercial downtime, you can anticipate significant savings in both time and money."
  },
  {
    title: "Reduce Your Overall Time-to-Hire",
    description: "Experience the impact of our candidate pre-assessments, elevating your interview-to-hire ratio and empowering you with the confidence to expedite the hiring process."
  },
  {
    title: "We lead with our insights",
    description: "Our unique positioning in the construction sector helps you stay competitive by offering tailored solutions for your projects. With our expertise, you'll be ready for the future and thrive in an ever-changing industry."
  },
]

const CardSection = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center py-6 sm:py-20'>
      <div className='flex mt-6 sm:mt-10 gap-4 sm:gap-6 flex-wrap mx-auto justify-center'>
        {
          cards.map((item, index) => (
            <div key={index * 20} className='flex flex-col max-w-[280px] about-card-shadow h-[350px] py-6 px-3 hover:bg-[#231E54] [&>div]:hover:bg-white [&>div>*]:hover:text-primary [&>h2]:hover:text-white [&>p]:hover:text-white transition-all duration-500 rounded-lg items-center gap-6'>
              <h2 className='text-xl sm:text-2xl inter-regular !font-semibold text-primary text-center'>{item.title}</h2>
              <p className='text-base inter-regular text-center'>{item.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default CardSection
