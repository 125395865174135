import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { IoLocationOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { fetchJobById } from "../../service/service";
import { JobType } from "../../types/types";
import PrimaryButton from "../common/PrimaryButton";
import Modal from "react-modal";
import * as Yup from "yup";
import FormikField from "../common/Field";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid } from "react-loader-spinner";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  resume: File | null;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  resume: Yup.mixed()
    .required("Resume is required")
    .test("fileType", "Only PDF files are allowed", (value) => {
      return value && value instanceof File && value.type === "application/pdf";
    }),
});

const initialValues: FormValues = {
  firstName: "",
  email: "",
  lastName: "",
  resume: null,
};

const JobPage = () => {
  const { id } = useParams<{ id: string }>();
  const [job, setJob] = useState<JobType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("resume", values.resume as File);

    let url = "";

    if (process.env.NODE_ENV === "development") {
      url = "http://localhost:3030/api/sendmail";
    } else {
      url = "https://aes-be.onrender.com/api/sendmail";
    }

    try {
      const response = await axios.post(url, formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response?.status === 500) {
        toast.error("Error while processing job");
        return;
      }

      if (response?.status === 200) {
        toast.success("Job applied successfully");
        setIsOpen(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error("An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getJob = async () => {
      try {
        const data = await fetchJobById(id as string);
        setJob(data.response?.data as JobType);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    getJob();
  }, [id]);

  if (loading) {
    return (
      <div className="pt-24 text-center text-3xl text-primary">Loading...</div>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl pt-32 items-center justify-center">
      <h1 className="text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary uppercase mb-10">
        Job description{" "}
      </h1>

      <div className="max-w-[1240px] w-full flex flex-col py-4 px-4 rounded-md sm:px-10 gap-5 my-6 shadow-md">
        <div className="w-full flex">
          <div className="opacity-50 py-1 inter-regular px-3 border rounded-xl">
            Actively Hiring
          </div>
        </div>

        <div className="w-full flex flex-col sm:flex-row justify-between items-end">
          <div className="flex flex-col gap-2 w-full">
            <img
              src={job?.companyImage as unknown as string}
              alt={job?.companyName}
              className="w-10 h-auto"
            />
            <div className="text-2xl text-primary">{job?.jobPosition}</div>
            <div className="text-xl text-[#979797]">{job?.companyName}</div>
          </div>
          <PrimaryButton onClick={() => setIsOpen(true)}>Apply</PrimaryButton>
        </div>

        <div className="w-full flex items-center gap-2 text-[#747474] text-xl">
          <IoLocationOutline />
          <div>{job?.location}</div>
        </div>
        <div className="flex gap-10 flex-wrap">
          <div className="flex flex-col gap-3">
            <h3 className="text-2xl text-[#979797] uppercase">Salary range</h3>
            <p className="text-[#747474] text-xl">
              {job?.currency === "GBP" ? (
                <>£</>
              ) : job?.currency === "EUR" ? (
                <>€</>
              ) : job?.currency === "INR" ? (
                <>₹</>
              ) : (
                <>$</>
              )}
              {job?.salaryRange.min} -{" "}
              {job?.currency === "GBP" ? (
                <>£</>
              ) : job?.currency === "EUR" ? (
                <>€</>
              ) : job?.currency === "INR" ? (
                <>₹</>
              ) : (
                <>$</>
              )}
              {job?.salaryRange.max}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="text-2xl text-[#979797] uppercase">Apply by</h3>
            <p className="text-[#747474] text-xl">
              {new Date(job?.listingExpiryDate as Date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
              )}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="text-2xl text-[#979797] uppercase">
              Company website
            </h3>
            <a
              href={job?.companyWebsite}
              target="_blank"
              rel="noreferrer"
              className="text-[#747474] text-xl"
            >
              {job?.companyWebsite}
            </a>
          </div>
        </div>
        <div className="w-full flex items-center gap-4 flex-wrap py-6 text-[#747474] text-xl">
          <p className="text-sm text-white px-4 py-1 bg-primary rounded-md">
            Posted{" "}
            {new Date(job?.createdAt as Date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="text-sm text-[#747474] px-4 py-1 bg-[#f3f3f3] rounded-md">
            {job?.jobType}
          </p>
        </div>
        <div className="w-full flex border-b-2">
          <p className="text-lg text-[#747474]">Be an early applicant</p>
        </div>
        <div className="w-full flex">
          <h2 className="text-2xl inter-regular text-primary">About the Job</h2>
        </div>

        <div className="w-full flex">
          <div
            className="mb-10"
            dangerouslySetInnerHTML={{ __html: job?.content as string }}
          ></div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="flex flex-col overflow-hidden bg-white max-w-[500px] items-center mt-40 justify-center p-5 shadow-xl mx-4 sm:mx-10 rounded-xl"
      >
        <h1 className="text-2xl sm:text-2xl text-center inter-regular-italic !font-bold text-primary uppercase mb-10 ">
          Apply to this job
        </h1>
        <div className="max-w-[1240px] w-full flex flex-col justify-center items-center bg-white p-0 sm:px-10 gap-5 my-6">
          <div className="w-full flex flex-col justify-center items-center">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form className="space-y-4">
                  {isSubmitting ? (
                    <div className="flex w-full h-[35rem] justify-center items-center">
                      <Grid
                        visible={true}
                        height="80"
                        width="80"
                        color="#2E388F"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperClass="grid-wrapper"
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <FormikField
                          label="First Name"
                          name="firstName"
                          type="text"
                        />
                      </div>
                      <div>
                        <FormikField
                          label="Last Name"
                          name="lastName"
                          type="text"
                        />
                      </div>

                      <div>
                        <FormikField label="Email" name="email" type="email" />
                      </div>

                      <div>
                        <label htmlFor="resume">Resume (PDF only)</label>
                        <input
                          name="resume"
                          type="file"
                          accept=".pdf"
                          className="flex w-full h-full px-3 py-2 border rounded-lg bg-white border-gray-300 shadow-sm poppins-regular focus:ring-0 active:ring-0 focus:outline-none"
                          onChange={(event) => {
                            const file = event.currentTarget.files?.[0] || null;
                            setFieldValue("resume", file);
                          }}
                        />
                        <ErrorMessage name="resume" component="div" />
                      </div>
                      <PrimaryButton
                        disabled={isSubmitting}
                        type="submit"
                        className="w-full"
                      >
                        Submit
                      </PrimaryButton>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobPage;
