import React from "react";
import Hero from "./Hero";
import Section2 from "./Section2";
import { Helmet } from "react-helmet";

const InfrastructurePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Infrastructure Recruitment Solutions | Alexander Executive Search
        </title>
        <meta
          name="description"
          content="Discover skilled professionals for the infrastructure sector with Alexander Executive Search. Our expertise connects top talent with key roles in transportation, utilities, and public works to support your critical projects with reliable, high-retention placements."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <Section2 />
      </div>
    </>
  );
};

export default InfrastructurePage;
