import React from "react";

import sectionDesign from "../../../assets/images/section-design.webp";

import infraImg1 from "../../../assets/images/infraImg1.webp";
import infraImg2 from "../../../assets/images/infraImg2.webp";
import infraImg3 from "../../../assets/images/infraImg3.webp";
import infraImg4 from "../../../assets/images/infraImg4.webp";

type SectionProp = {
  image: string;
  text: JSX.Element;
  link: string;
};
const sections: SectionProp[] = [
  {
    image: infraImg1,
    text: (
      <div className="">
        <h2
          data-aos="fade-left"
          className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
        >
          Why Choose Alexander Executive Search?
        </h2>

        <ul data-aos="fade-left" data-aos-delay="100">
          <li>
            1. Sector Specialization: We understand the unique challenges of
            infrastructure projects.
          </li>
          <li>
            2. Global Reach: Our international presence ensures access to a
            diverse talent pool.
          </li>
          <li>
            3. Hard-to-Find Talent: We excel at sourcing specialized skills for
            complex projects.
          </li>
          <li>
            4. Project Insights: Our industry knowledge translates to more
            targeted recruitment.
          </li>
          <li>
            5. Agile Recruitment: We adapt quickly to evolving project needs and
            timelines.
          </li>
        </ul>
      </div>
    ),
    link: "",
  },

  {
    image: infraImg2,
    text: (
      <div className="sm:mx-10 mt-16">
        <h2
          data-aos="fade-left"
          className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
        >
          Our Services
        </h2>

        <ul data-aos="fade-left" data-aos-delay="100">
          <li>1. Onshore and offshore talent acquisition</li>
          <li>2. Project-specific team building</li>
          <li>2. International mobilization support</li>
        </ul>
      </div>
    ),
    link: "",
  },

  {
    image: infraImg3,
    text: (
      <div className="flex flex-col gap-4 sm:mx-10 mt-16">
        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          Whether you’re breaking ground on a new airport or expanding rail
          networks, Alexander Executive Search delivers the skilled
          professionals your infrastructure project demands.
        </p>
        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          Contact us to learn how we can support your talent needs in:
        </p>

        <ul data-aos="fade-left" data-aos-delay="100">
          <li>1. Civil Engineering</li>
          <li>2. Construction Management</li>
          <li>3. Rail Systems</li>
          <li>4. Airport Development</li>
          <li>5. And more</li>
        </ul>

        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          Alexander Executive Search: Building the teams that build the future.
        </p>
      </div>
    ),
    link: "",
  },
  {
    image: infraImg4,
    text: (
      <div className="sm:mx-10 mt-16">
        <h2
          data-aos="fade-left"
          className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
        >
          Infrastructure disciplines we recruit for
        </h2>

        <ul data-aos="fade-left" data-aos-delay="100">
          <li>1. Construction Management</li>
          <li>2. OHW Engineering</li>
          <li>3. Permanent Way Engineer</li>
          <li>4. IT &amp; Telecoms</li>
          <li>5. Project Engineering</li>
          <li>6. Project Management</li>
          <li>7. Structural Engineers</li>
          <li>8. Civil Site Engineers</li>
          <li>9. Tunnel Engineers</li>
        </ul>
      </div>
    ),
    link: "",
  },
];

const Section2 = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center my-6 px-4 sm:mt-20 sm:mx-auto">
      <h1 className="text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary uppercase">
        Infrastructure <br /> Recruitment expert
      </h1>
      <p className="text-lg sm:text-xl text-center inter-regular text-primary mt-4 sm:mt-10 max-w-7xl">
        Infrastructure Recruitment
      </p>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-6 max-w-7xl">
        Alexander Executive Search: Your Global Partner in Infrastructure Talent
        Acquisition With offices in New York and London, Alexander Executive
        Search brings unparalleled expertise to infrastructure recruitment
        worldwide. Our deep experience spans major civil, construction, rail,
        and airport projects across the globe.
      </p>

      <div className="flex sm:hidden flex-col sm:flex-row relative justify-center items-center gap-4 sm:gap-8 my-4 sm:my-20 w-full">
        {sections.map((section, index) => (
          <div
            key={index}
            className="bg-primary cursor-pointer sm:h-[800px] max-w-[400px] [&>.img1]:hover:scale-125 [&>.img2]:hover:translate-y-10 [&>.text]:hover:translate-y-10 relative overflow-hidden"
          >
            <img
              className="img1 object-cover w-full sm:h-[500px] relative transition duration-300 z-10"
              src={section.image}
              alt="engg"
            />
            <img
              className="img2 -mt-28 w-full relative z-20 h-[450px] transition duration-300"
              src={sectionDesign}
              alt="engg"
            />
            <div className="text absolute z-40 top-96 text-left mx-2 text-white transition duration-300">
              {section.text}
            </div>
          </div>
        ))}
      </div>
      <div className="hidden sm:block">
        <div className="flex flex-col sm:flex-row justify-between items-center max-w-screen-xl mx-auto gap-4 sm:gap-6 xl:gap-0 my-10">
          <div
            data-aos="fade-right"
            className="w-[90%] h-[520px] overflow-hidden"
          >
            <img src={infraImg1} alt="infra" />
          </div>
          <div className="sm:w-full relative text-white">
            <img
              className="img2 -mt-[7.5rem] w-full absolute transition duration-300"
              src={sectionDesign}
              alt="engg"
            />
            <div className="sm:mx-10 mt-16">
              <h2
                data-aos="fade-left"
                className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
              >
                Why Choose Alexander Executive Search?
              </h2>

              <ul data-aos="fade-left" data-aos-delay="100">
                <li>
                  1. Sector Specialization: We understand the unique challenges
                  of infrastructure projects.
                </li>
                <li>
                  2. Global Reach: Our international presence ensures access to
                  a diverse talent pool.
                </li>
                <li>
                  3. Hard-to-Find Talent: We excel at sourcing specialized
                  skills for complex projects.
                </li>
                <li>
                  4. Project Insights: Our industry knowledge translates to more
                  targeted recruitment.
                </li>
                <li>
                  5. Agile Recruitment: We adapt quickly to evolving project
                  needs and timelines.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row w-full justify-between items-center max-w-screen-xl mx-auto gap-4 sm:gap-6 xl:gap-0 my-10">
          <div className="sm:w-full relative text-white">
            <img
              className="img2 -mt-32 w-full absolute transition duration-300 object-cover object-top h-[443px]"
              src={sectionDesign}
              alt="engg"
            />
            <div className="sm:mx-10 mt-16">
              <h2
                data-aos="fade-right"
                className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
              >
                Our Services
              </h2>

              <ul data-aos="fade-right" data-aos-delay="100">
                <li>1. Onshore and offshore talent acquisition</li>
                <li>2. Project-specific team building</li>
                <li>2. International mobilization support</li>
              </ul>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full h-full">
            <img src={infraImg2} alt="infra" />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center max-w-screen-xl mx-auto gap-4 sm:gap-6 xl:gap-0 my-10">
          <div
            data-aos="fade-right"
            className="w-[90%] h-[520px] overflow-hidden"
          >
            <img src={infraImg3} alt="infra" />
          </div>
          <div className="sm:w-full relative text-white">
            <img
              className="img2 -mt-20 w-full absolute transition duration-300"
              src={sectionDesign}
              alt="engg"
            />
            <div className="flex flex-col gap-4 sm:mx-10 mt-16">
              <p
                data-aos="fade-left"
                data-aos-delay="100"
                className="text-sm sm:text-lg inter-regular mb-2 leading-5"
              >
                Whether you’re breaking ground on a new airport or expanding
                rail networks, Alexander Executive Search delivers the skilled
                professionals your infrastructure project demands.
              </p>
              <p
                data-aos="fade-left"
                data-aos-delay="100"
                className="text-sm sm:text-lg inter-regular mb-2 leading-5"
              >
                Contact us to learn how we can support your talent needs in:
              </p>

              <ul data-aos="fade-left" data-aos-delay="100">
                <li>1. Civil Engineering</li>
                <li>2. Construction Management</li>
                <li>3. Rail Systems</li>
                <li>4. Airport Development</li>
                <li>5. And more</li>
              </ul>

              <p
                data-aos="fade-left"
                data-aos-delay="100"
                className="text-sm sm:text-lg inter-regular mb-2 leading-5"
              >
                Alexander Executive Search: Building the teams that build the
                future.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row w-full justify-between items-center max-w-screen-xl mx-auto gap-4 sm:gap-6 xl:gap-0 my-10">
          <div className="sm:w-full relative text-white">
            <img
              className="img2 -mt-24 w-full absolute transition duration-300 object-cover object-top"
              src={sectionDesign}
              alt="engg"
            />
            <div className="sm:mx-10 mt-16">
              <h2
                data-aos="fade-right"
                className="text-lg sm:text-2xl inter-regular mb-2 sm:mb-4 !font-medium"
              >
                Infrastructure disciplines we recruit for
              </h2>

              <ul data-aos="fade-right" data-aos-delay="100">
                <li>1. Construction Management</li>
                <li>2. OHW Engineering</li>
                <li>3. Permanent Way Engineer</li>
                <li>4. IT &amp; Telecoms</li>
                <li>5. Project Engineering</li>
                <li>6. Project Management</li>
                <li>7. Structural Engineers</li>
                <li>8. Civil Site Engineers</li>
                <li>9. Tunnel Engineers</li>
              </ul>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full ">
            <img
              src={infraImg4}
              alt="infra"
              className="h-[514px] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
