import React from "react";
import { Link } from "react-router-dom";
import { BlogType } from "../../types/types";
import { format } from "date-fns";
import { deleteBlog } from "../../service/service";
import { toast } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

interface BlogProps {
  blog: BlogType;
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
  setBlogs?: React.Dispatch<React.SetStateAction<BlogType[]>>;
}

const delBlog = async (
  blogId: string,
  setJobs: React.Dispatch<React.SetStateAction<BlogType[]>>,
) => {
  if (!window.confirm("Are you sure you want to delete this blog?")) {
    return;
  }

  try {
    const response = await deleteBlog(blogId);
    if (response.status === false) {
      console.log("Job deletion failed:", response.response?.data.message);
    } else {
      setJobs((prevJobs) => prevJobs.filter((job) => job._id !== blogId));
      console.log("Job deleted successfully:");
      toast.success("Job deleted successfully");
    }
  } catch (error) {
    toast.error("Error deleting job");
    console.error("Error deleting job:", error);
  }
};

const Blog = ({
  blog,
  isEditable,
  setMode,
  setEditBlogData,
  setBlogs,
}: BlogProps) => {
  return (
    <div className="relative">
      {isEditable && setMode && setEditBlogData && setBlogs && (
        <div className="absolute z-10 flex gap-2 top-6 right-6">
          <div
            onClick={() => delBlog(blog._id, setBlogs)}
            className="p-2 bg-gray-300 rounded-full"
          >
            <RiDeleteBin6Line className="opacity-50 cursor-pointer " />
          </div>
          <div
            onClick={() => {
              setMode("edit");
              setEditBlogData(blog);
              window.scrollTo(0, 0);
            }}
            className="p-2 bg-gray-300 rounded-full"
          >
            <FaEdit className="opacity-50 cursor-pointer " />
          </div>
        </div>
      )}
      <Link
        to={`/blog/${blog._id}`}
        state={{ blog }}
        onClick={() => window.scrollTo(0, 0)}
        className="flex px-3 py-3"
      >
        <div className="max-w-sm overflow-hidden">
          <img
            className="max-h-[200px] w-80 object-cover hover:scale-105 transition-all duration-300"
            src={blog.image as unknown as string}
            alt="Sunset in the mountains"
          />
          <div className="py-4">
            <div className="mb-2 text-sm font-semibold text-primary">
              {blog.author} • {format(blog.date, "dd MMM yyyy")}
            </div>
            <div className="flex mb-2 justify-between cursor-pointer [&>svg]:hover:rotate-45 apply underline-offset-4 [&>div]:hover:text-black [&>svg]:hover:text-black transition-all duration-400">
              <p className="text-xl transition-all inter-bold duration-400">
                {blog.title}
              </p>
              <svg
                className="text-2xl transition-all duration-300"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M7.33337 17L17.3334 7M17.3334 7H7.33337M17.3334 7V17"
                  stroke="#1A1A1A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className="text-base text-gray-700">{blog.subHeading}</p>
          </div>
          <div className="mb-1">
            <div className="flex flex-wrap gap-2 mb-2">
              {blog.tags.map((tag) => (
                <div className="py-1 px-2 bg-opacity-10 rounded-lg bg-[#267DFF] text-primary text-xs">
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Blog;
