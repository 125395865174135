import React from "react";

const EmbeddedSite = () => {
  return (
    <div className="w-full h-[800px]">
      <iframe
        src="https://aesrecruitment.usgoodhire.agency"
        title="Embedded Website"
        width="100%"
        height="1000px"
        style={{ border: "none" }}
        onError={(e) => {
          // You can handle the error here, e.g., show an error message
          e.currentTarget.srcdoc = "<p>Embedded data could not be displayed.</p>";
        }}
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  );
};

export default EmbeddedSite;
