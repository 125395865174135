import axios from "axios";

let url = "https://www.aesrecruitment.com/api/";

if (process.env.NODE_ENV === "development") {
  url = "http://localhost:3030/api/";
}

const axiosClient = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosClient;
