import React from 'react'
import { GoArrowUpRight } from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import article1 from "../../assets/images/article1.webp"
import article2 from "../../assets/images/article2.webp"
import article3 from "../../assets/images/article3.webp"
import "swiper/css";
import 'swiper/css/pagination';

type ArticleType = {
  title: string;
  description: string;
  image: string;
  link: string;
  category: {
    category1: string;
    category2: string;
  }
  date: {
    date: string;
    month: string;
  }
}

const articles: ArticleType[] = [
  {
    title: "Civil Engineering Recruitment",
    description: "Alexander Executive Search, an esteemed search firm with a specialization in Civil Engineering recruitment.",
    image: article1,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN"
    },
    link: ""
  },
  {
    title: "Search Your Job",
    description: "Alexander Executive Search has been a driving force behind energy companies, offering exceptional talent for specialized Energy contracts.",
    image: article2,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN"
    },
    link: ""
  },
  {
    title: "Search Your Job",
    description: "Alexander Executive Search has been a driving force behind energy companies, offering exceptional talent for specialized Energy contracts.",
    image: article3,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN"
    },
    link: ""
  },
  {
    title: "Civil Engineering Recruitment",
    description: "Alexander Executive Search, an esteemed search firm with a specialization in Civil Engineering recruitment.",
    image: article1,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN"
    },
    link: ""
  },
]

type Props = {
  className?: string
}

const Articles = ({ className }: Props) => {
  return (
    <div className={`w-full flex flex-col justify-center items-center ${className}`}>

      <h2 className='text-3xl sm:text-5xl text-center inter-regular my-6 sm:mt-20 sm:mb-10'>Explore Our Lastest Articles</h2>

      <div className="max-w-screen-xl w-full h-full flex justify-center items-center">

        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          className="flex justify-center items-center"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          pagination={true}
          loop
        >
          {
            articles.map((article, index) => (
              <SwiperSlide className="p-4" key={index}>

                <div className="w-full shadow-2xl max-w-full p-4 rounded-xl article-shadow lg:max-w-[380px] max-h-[500px] sm:h-[500px] flex flex-col">
                  <div className='p-2 rounded-xl'>

                    <div className="[&>img]:hover:scale-110 [&>div]:hover:scale-110 relative h-[200px]">
                      <img className="object-none w-full transition-all duration-300 -z-10 h-[200px] rounded-xl"
                        src={article.image}
                        alt={article.title} />
                      <div
                        className="hover:bg-transparent rounded-xl transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                      </div>
                      <a href="#!">
                        <div
                          className="absolute top-0 right-0 bg-primary px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-primary transition rounded-lg flex flex-col items-center duration-500 ease-in-out">
                          <div className='text-2xl font-bold'>
                            {article.date.date}
                          </div>
                          <div className='text-xs -mt-1'>
                            {article.date.month}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-grow">
                    <div className="flex flex-col justify-between px-2 py-2 bg-white">
                      <div>
                        <div className='flex gap-2 mb-2'>
                          <div className='py-1 px-2 bg-opacity-10 rounded-lg bg-[#267DFF] text-primary text-xs'>{article.category.category1}</div>
                          <div className='py-1 px-2 bg-opacity-10 rounded-lg bg-[#267DFF] text-primary text-xs'>{article.category.category2}</div>
                        </div>
                        <div
                          className="block mb-2 text-lg sm:text-xl cursor-pointer inter-regular hover:underline text-primary !font-semibold">
                          {article.title}
                        </div>
                        <p className="mb-10 text-[16px]">{article.description}</p>
                      </div>
                      <div className='flex items-center'>
                      <div className='flex items-center cursor-pointer [&>svg]:hover:rotate-45 underline-offset-2 [&>div]:hover:text-black [&>svg]:hover:text-black transition-all duration-400'>

                        <div className='text-primary inter-regular text-sm underline'>

                          Read more
                        </div>
                        <GoArrowUpRight className='text-primary transition-all duration-300 p-1 text-2xl' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

            ))
          }

        </Swiper>
      </div>
    </div>
  )
}

export default Articles
