import React from "react";
import Hero from "./Hero";
import JobBoard from "./JobBoard";
import { Helmet } from "react-helmet";

const JobBoardPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Alexander Executive Search Job Board | Careers in Civil Engineering &
          Executive Roles
        </title>
        <meta
          name="description"
          content="Explore our curated job board with top opportunities in civil engineering and executive roles. Find your next career move with Alexander Executive Search's latest postings for talented professionals in the industry."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <JobBoard />
      </div>
    </>
  );
};

export default JobBoardPage;
