import React from "react";
import Contact from "../HomePage/Contact";
import ArticleSection from "./ArticleSection";
import CardSection from "./CardSection";
import Hero from "./Hero";
import Section2 from "./Section2";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>
          About Us | Alexander Executive Search – Civil Engineering & Executive
          Recruitment Specialists
        </title>
        <meta
          name="description"
          content="Learn about Alexander Executive Search, a trusted partner in civil engineering recruitment, with a data-driven, people-focused approach to sourcing top talent and enhancing team retention for sustainable growth in the engineering sector."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <Section2 />
        <CardSection />
        <ArticleSection />
        <Contact />
      </div>
    </>
  );
};

export default AboutPage;
