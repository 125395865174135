import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import heroImg from "../../assets/images/heroImg.webp";
import PrimaryButton from "../common/PrimaryButton";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="relative mt-[64px] sm:mt-[72px] w-full snap-center h-[500px] pt-16 pb-24 flex content-center items-center justify-center">
      <div
        className="absolute top-0 w-full h-full bg-center bg-cover"
        style={{
          backgroundImage: `url('${heroImg}')`,
        }}
      >
        <motion.span
          initial={{ opacity: 0.8 }}
          animate={{ opacity: 0.2 }}
          transition={{ duration: 1 }}
          id="blackOverlay"
          className="w-full h-full absolute opacity-20 bg-black"
        ></motion.span>
      </div>
      <main>
        <div className="relative px-4 lg:px-8">
          <div className="mx-auto max-w-5xl pt-6">
            <div className="text-center">
              <motion.h1
                initial={{ opacity: 0, translateY: 40 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5 }}
                className="text-2xl inter-regular-italic decoration-solid text-white italic tracking-tight lg:text-6xl"
              >
                <span className="font-bold custom-gradient-2">ALEXANDER</span>{" "}
                EXECUTIVE SEARCH
              </motion.h1>{" "}
              <motion.p
                initial={{ opacity: 0, translateY: 40 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.8 }}
                className="mt-3 text-xl sm:text-3xl leading-8 text-white sm:font-gotham-thin font-[300]"
              >
                Empowering Excellence in Civil Engineering Recruitment
              </motion.p>
              <div className="flex gap-4 justify-center mt-10">
                <PrimaryButton onClick={() => navigate("/job-board")}>
                  Our Jobs
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => navigate("/contact")}
                  className="!text-black !bg-white"
                >
                  Contact Us
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Hero;
