import React from 'react'
import { FaLocationDot, FaMoneyBillWave } from 'react-icons/fa6'
import { GoArrowUpRight } from 'react-icons/go'
import { JobType } from '../../types/types'
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { deleteJob } from '../../service/service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

type Props = {
  jobs: JobType[];
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<'create' | 'edit'>>
  setJobs?: React.Dispatch<React.SetStateAction<JobType[]>>
  setEditJobData?: React.Dispatch<React.SetStateAction<JobType>>
};

const delJob = async (jobId: string, setJobs: React.Dispatch<React.SetStateAction<JobType[]>>) => {

  if (!window.confirm("Are you sure you want to delete this job?")) {
    return
  }

  try {
    const response = await deleteJob(jobId);
    if (response.status === false) {
      console.log("Job deletion failed:", response.response?.data.message);
    } else {
      setJobs((prevJobs) => prevJobs.filter((job) => job._id !== jobId));
      console.log("Job deleted successfully:");
      toast.success("Job deleted successfully");
    }
  }
  catch (error) {
    toast.error("Error deleting job");
    console.error("Error deleting job:", error);
  }
}

const Jobs = ({ jobs, isEditable, setMode, setEditJobData, setJobs }: Props) => {

  const navigate = useNavigate();

  return (
    <>
      {
        jobs.map((job, index) => (
          <div key={index} className="border p-4 mb-4 inter-regular hover:bg-primary transition-all duration-400 [&>div>div>.date]:hover:text-white [&>p]:hover:text-white [&>div>div>h2]:hover:text-white rounded-md shadow-md bg-white [&>.location>p]:hover:text-white [&>.pay-wrapper>.pay>p]:hover:text-white [&>div>.apply>*]:hover:text-white">
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <img src={job.companyImage as unknown as string} className="w-10 h-10 object-cover rounded-full" alt={job.companyName} />
                <h2 className="text-lg">{job.jobPosition}</h2>
              </div>
              <div className="flex gap-4 items-center">
                <p className="text-sm text-[#747474] date transition-all duration-400">Posted {format(new Date(job.createdAt), 'MMM dd, yyyy')} </p>
                <p className="text-sm text-[#747474] px-4 py-1 bg-[#f3f3f3] rounded">{job.jobType}</p>
                {
                  isEditable && setMode && setEditJobData && setJobs &&
                  <>
                    <div onClick={() => delJob(job._id, setJobs)} className='p-2 bg-gray-300 rounded-full'>
                      <RiDeleteBin6Line className=" opacity-50 cursor-pointer" />
                    </div>
                    <div onClick={() => { setMode('edit'); setEditJobData(job); window.scrollTo(0, 0) }} className='p-2 bg-gray-300 rounded-full'>
                      <FaEdit className=" opacity-50 cursor-pointer" />
                    </div>
                  </>
                }
              </div>
            </div>
            <p className="opacity-50">{job.companyName}</p>
            <div className="flex gap-2 items-center location transition-all duration-400">
              <FaLocationDot className="text-[#747474] opacity-50" />
              <p className="opacity-50">{job.location}</p>
            </div>
            <div className="flex justify-between pay-wrapper">
              <div className="flex gap-2 items-center pay transition-all duration-400">
                <FaMoneyBillWave className="text-[#747474] opacity-50" />
                <p className="opacity-50">{job.currency === "GBP" ? <>£</> : job.currency === "EUR" ? <>€</> : job.currency === "INR" ? <>₹</> : <>$</>
                }{job.salaryRange.min} - {job.salaryRange.max}</p>
              </div>

              <div onClick={() => navigate(`/jobs/${job._id}`) } className='flex items-center cursor-pointer [&>svg]:hover:rotate-45 apply underline-offset-4 [&>div]:hover:text-black [&>svg]:hover:text-black transition-all duration-400'>
                <div className='text-primary inter-regular text-sm underline transition-all duration-400'>
                  Apply now
                </div>
                <GoArrowUpRight className='text-primary transition-all duration-300 p-1 text-2xl' />
              </div>
            </div>
          </div>
        ))
      }
    </>
  )
}


export default Jobs
