import React, { useEffect } from "react";
import HomePage from "./components/HomePage/HomePage";
import { Routes, Route, Navigate } from "react-router-dom";
import Nav from "./components/Nav";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/Footer";
import AboutPage from "./components/AboutPage/AboutPage";
import JobBoardPage from "./components/JobBoardPage/JobBoardPage";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import Login from "./components/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateJob from "./components/CreateJob/CreateJob";
import Blogs from "./components/Blogs/Blogs";
import OurProcessPage from "./components/OurProcessPage/OurProcessPage";
import BlogDetailPage from "./components/Blogs/BlogDetailPage";
import CreateBlog from "./components/CreateBlog/CreateBlog";
import JobPage from "./components/JobBoardPage/JobPage";
import Contact from "./components/ContactPage/Contact";
import EngineeringPage from "./components/SectorPages/Engineering/EngineeringPage";
import CivilEngineeringPage from "./components/SectorPages/CivilEngineering/CivilEngineeringPage";
import InfrastructurePage from "./components/SectorPages/Infrastructure/InfrastructurePage";
import Privacy from "./components/Privacy";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <AuthProvider>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/our-process" element={<OurProcessPage />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:id" element={<BlogDetailPage />} />
          <Route path="/jobs/:id" element={<JobPage />} />
          <Route path="/job-board" element={<JobBoardPage />} />
          <Route path="/engineering" element={<EngineeringPage />} />
          <Route path="/civil-engineering" element={<CivilEngineeringPage />} />
          <Route path="/infrastructure" element={<InfrastructurePage />} />
          <Route path="/create-job" element={<CreateJob />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

const ProtectedRoute = ({ component }: { component: JSX.Element }) => {
  const authContext = React.useContext(AuthContext);

  if (authContext === undefined) {
    return <div>Loading...</div>;
  }

  return authContext.isAuthenticated ? component : <Navigate to="/login" />;
};

export default App;
