import React from "react";
import Articles from "./Articles";
import ExecTalent from "./ExecTalent";
import Hero from "./Hero";
import SpecialistRec from "./SpecialistRec";
import Section5 from "./Section5";
import OurProcess from "./OurProcess";
import Contact from "./Contact";
import Progress from "./Progress";
import EmbeddedSite from "../EmbeddedSite";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Alexander Executive Search | Expert Civil Engineering Recruitment &
          Retention Solutions
        </title>
        <meta
          name="description"
          content="Alexander Executive Search provides specialized civil engineering recruitment solutions, blending innovative assessment tools with industry expertise. We connect you with top engineering talent for long-term success, backed by our proven process to enhance retention and boost your team’s performance."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <SpecialistRec />
        <Articles />
        <ExecTalent />
        <Section5 />
        <OurProcess />
        <EmbeddedSite />
      </div>
      <Progress />
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Contact />
      </div>
    </>
  );
};

export default HomePage;
