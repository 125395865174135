import React from "react";
import _ from "lodash";

function Separator(props: { turns: number; style: React.CSSProperties }) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props: { count: number; style: React.CSSProperties }) {
  const turns = 1 / props.count;

  // Wrap in a React.Fragment or a div to make sure it returns a single element
  return (
    <React.Fragment>
      {_.range(props.count).map((index: number) => (
        <Separator key={index} turns={index * turns} style={props.style} />
      ))}
    </React.Fragment>
  );
}

export default RadialSeparators;

