import React from "react";
import laptopImg from "../../assets/images/laptop.webp";
import PrimaryButton from "../common/PrimaryButton";
import article1 from "../../assets/images/article1.webp";
import article2 from "../../assets/images/article2.webp";
import article3 from "../../assets/images/article3.webp";
import Articles from "../HomePage/Articles";

type ArticleType = {
  title: string;
  description: string;
  image: string;
  link: string;
  category: {
    category1: string;
    category2: string;
  };
  date: {
    date: string;
    month: string;
  };
};

const articles: ArticleType[] = [
  {
    title: "Civil Engineering Recruitment",
    description:
      "Alexander Executive Search, an esteemed search firm with a specialization in Civil Engineering recruitment.",
    image: article1,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN",
    },
    link: "",
  },
  {
    title: "Search Your Job",
    description:
      "Alexander Executive Search has been a driving force behind energy companies, offering exceptional talent for specialized Energy contracts.",
    image: article2,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN",
    },
    link: "",
  },
  {
    title: "Search Your Job",
    description:
      "Alexander Executive Search has been a driving force behind energy companies, offering exceptional talent for specialized Energy contracts.",
    image: article3,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN",
    },
    link: "",
  },
  {
    title: "Civil Engineering Recruitment",
    description:
      "Alexander Executive Search, an esteemed search firm with a specialization in Civil Engineering recruitment.",
    image: article1,
    category: {
      category1: "Civil Engineering",
      category2: "Engineering",
    },
    date: {
      date: "10",
      month: "JAN",
    },
    link: "",
  },
];

const ArticleSection = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="relative flex flex-col-reverse sm:flex-row px-4 my-6 sm:px-0 items-center justify-between w-full max-w-screen-2xl gap-4">
        <svg
          className="absolute -z-10 sm:bottom-36 bottom-72"
          xmlns="http://www.w3.org/2000/svg"
          width="auto"
          height="auto"
          viewBox="0 0 1440 527"
          fill="none"
        >
          <path
            d="M-1 527V0L1440 72.6295V527H-1Z"
            fill="url(#paint0_linear_9_3075)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_9_3075"
              x1="364.94"
              y1="-376"
              x2="521.277"
              y2="768.192"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2E3192" />
              <stop offset="0.458035" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
        <Articles className="[&>h2]:text-primary" />
      </div>
    </div>
  );
};

export default ArticleSection;
