import React from "react";
import civilEngPageImg from "../../../assets/images/civilEngPageImg1.webp";

const Section2 = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center my-6 px-4 sm:mt-20 sm:mx-auto">
      <h1 className="text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary uppercase">
        Supplying Next-Generation <br /> Executives and Leaders
      </h1>
      <p className="text-lg sm:text-xl text-center inter-regular text-primary mt-4 sm:mt-10 max-w-7xl">
        Civil Engineering Recruitment
      </p>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-6 max-w-7xl">
        The engineering industry faces numerous challenges, including a
        widespread shortage of skilled professionals, supply chain disruptions,
        and inflation. In this demanding landscape, securing transformative
        leadership is more crucial than ever. Alexander Executive Search is a
        premier engineering recruitment agency committed to helping clients
        source, hire, and retain qualified candidates for executive and
        leadership roles. Our recruitment services are designed to connect
        clients with top-tier engineering talent who are not only highly capable
        but also passionate about driving the engineering industry forward.
      </p>

      <div className="flex flex-col sm:flex-row border-[10px] border-white transition-all duration-300 hover:border-primary justify-between items-center background-linear-grad max-w-screen-xl gap-4 sm:gap-6 xl:gap-0 my-10">
        <div data-aos="fade-right" className="w-full">
          <img src={civilEngPageImg} alt="engg" />
        </div>
        <div className="sm:w-[90%] sm:p-6">
          <h2
            data-aos="fade-left"
            className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
          >
            Our Approach to Civil Engineering Executive Search
          </h2>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            The engineering industry faces numerous challenges, including a
            widespread shortage of skilled professionals, supply chain
            disruptions, and inflation. In this demanding landscape, securing
            transformative leadership is more crucial than ever.
          </p>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            Alexander Executive Search is a premier engineering recruitment
            agency committed to helping clients source, hire, and retain
            qualified candidates for executive and leadership roles. Our
            recruitment services are designed to connect clients with top-tier
            engineering talent who are not only highly capable but also
            passionate about driving the engineering industry forward.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
