import React from 'react'
import { LiaHandshakeSolid } from "react-icons/lia";
import { RiCustomerService2Fill } from "react-icons/ri";
import { TbDevicesSearch } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { motion, Variants } from 'framer-motion';


type Process = {
  icon: JSX.Element,
  title: string,
  description: string
}

const process: Process[] = [
  {
    icon: <LiaHandshakeSolid className="w-8 h-8 text-white" />,
    title: "Find and attract the best talent",
    description: "We work in partnership with you to perfectly position your business and attract great people."
  },
  {
    icon: <RiCustomerService2Fill className="w-8 h-8 text-white" />,
    title: "Reduce your downtime",
    description: "Cutting edge technology allows us to drastically reduce the total number of interviews needed."
  },
  {
    icon: <TbDevicesSearch className="w-8 h-8 text-white" />,
    title: "Improve your hiring accuracy",
    description: "We use psychometrics to objectively assess hiring teams and candidates, creating the perfect match."
  },
  {
    icon: <GiReceiveMoney className="w-8 h-8 text-white" />,
    title: "Reduce your cost to hire",
    description: "On average, a firm will retain only 70% of new hires over 12 months. Our clients experience 96% retention."
  },
]

const rotateY: Variants = {
  animate: { rotateY: 360, transition: { duration: 0.8, ease: "easeIn" } },
}


const OurProcess = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center my-6 sm:my-10'>
      <div className="text-center flex flex-col w-full justify-center items-center">
        <h4 data-aos="fade-up" className="text-xl sm:text-2xl text-primary mb-1">Our process</h4>
        <h2 data-aos="fade-up" data-aos-delay="100" className='text-3xl sm:text-[42px] sm:leading-[50px] inter-regular mb-4 !font-medium text-black'>
          Solutions To Improve Performance
        </h2>
        <p data-aos="fade-up" data-aos-delay="200" className='text-center text-[#4f4f4f] max-w-[450px]'>
          Our Recruitment process has filled positions globally and has consistently proven to:
        </p>
        <div className='flex mt-6 sm:mt-10 gap-3 sm:gap-6 flex-wrap mx-auto justify-center'>
          {
            process.map((item, index) => (
              <motion.div whileHover="animate" key={index * 20} className='flex [&>div*]:hover:[transform:rotateX(360deg)] flex-col max-w-[280px] h-[300px] py-6 px-3 hover:bg-[#231E54] [&>div]:hover:bg-white [&>div>*]:hover:text-primary [&>h2]:hover:text-white [&>p]:hover:text-white transition-all duration-500 rounded-lg items-center gap-4'>
                <motion.div variants={rotateY} className='p-3 bg-primary rounded-full'>
                  {item.icon}
                </motion.div>
                <h2 className='text-xl sm:text-2xl inter-regular !font-semibold'>{item.title}</h2>
                <p className='text-base poppins-regular'>{item.description}</p>
              </motion.div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default OurProcess
