import React from "react";
import { useNavigate } from "react-router-dom";
import aboutPageImg from "../../assets/images/aboutPageImg.webp";
import PrimaryButton from "../common/PrimaryButton";

const Section2 = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col justify-center items-center my-6 px-4 sm:mt-20 sm:mx-auto">
      <h1 className="text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary uppercase">
        Headhunting Professionals <br /> with a partnership focus
      </h1>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-10 max-w-7xl">
        Alexander Executive Search is an expert search recruitment agency
        specializing in the Civil Engineering and construction industries. With
        offices in London, UK, and New York, USA, we cater to clients on an
        international scale. Our recruitment procedure is meticulously designed
        and intentionally crafted to incorporate multiple layers of scientific
        and objective evaluation. This ensures that we find the perfect match to
        meet your project needs.
      </p>
      <p className="text-sm sm:text-lg text-center inter-regular mt-4 sm:mt-6 max-w-7xl">
        What sets us apart is our multi-dimensional approach, tailored to
        address the intricacies of the 21st century. Unlike the outdated
        one-dimensional recruitment processes of the past, our method is
        adaptive and responsive to the complexities of the modern professional
        landscape. With us, you can be confident in finding the most suitable
        talent to drive your projects to success in business.
      </p>

      <PrimaryButton
        onClick={() => {
          navigate("/job-board");
          window.scrollTo(0, 0);
        }}
        className="mt-10"
      >
        Job Board
      </PrimaryButton>
      <div className="flex flex-col sm:flex-row border-[10px] border-white transition-all duration-300 hover:border-primary justify-between items-center background-linear-grad max-w-screen-xl gap-4 sm:gap-6 xl:gap-0 my-10">
        <div data-aos="fade-right" className="w-full">
          <img src={aboutPageImg} alt="engg" />
        </div>
        <div className="sm:w-[90%] sm:p-6">
          <h2
            data-aos="fade-left"
            className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
          >
            Why Alexander Executive Search?
          </h2>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            In the realm of Civil Engineering recruitment, conducting a
            successful Recruitment Search at all levels has become increasingly
            challenging, making the choice of the right partner a crucial
            factor. At Alexander Executive Search, we boast a rich heritage in
            retained search, earning a reputation for our expertise in assisting
            clients to make the right decisions and ensuring they hire the
            perfect candidate on the very first attempt. The reasons behind our
            success are straightforward:
          </p>
        </div>
      </div>
      <hr className="w-[90%] border px-20 border-primary opacity-50" />
    </div>
  );
};

export default Section2;
