import React from "react";
import EmbeddedSite from "../EmbeddedSite";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact Alexander Executive Search | Expert Recruitment Services
        </title>
        <meta
          name="description"
          content="Get in touch with Alexander Executive Search for personalized recruitment solutions. Whether you're looking for top talent in engineering, infrastructure, or any other sector, our team is ready to assist you. Reach out today to discuss your hiring needs."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <div className="w-full flex flex-col sm:flex-row mt-[64px] sm:mt-[72px]">
          <ContactForm />
          <div className="px-4 sm:px-6 lg:px-8 py-12">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.144826467006!2d-73.97184682397199!3d40.7588392713867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4bd28d96f%3A0xdcacdb8559ef3c90!2s641%20Lexington%20Ave%2C%20New%20York%2C%20NY%2010022%2C%20USA!5e0!3m2!1sen!2sin!4v1730222819809!5m2!1sen!2sin"
              width="600"
              height="600"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        <EmbeddedSite />
      </div>
    </>
  );
};

export default Contact;
