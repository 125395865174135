import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaAngleRight, FaInstagram } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import PrimaryButton from "./common/PrimaryButton";
import { AuthContext } from "../context/AuthContext";

// Validation schema using Yup
const SubscribeSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  if (!authContext) return null;

  return (
    <footer className="w-full footer-grad">
      <div className="mx-auto relative max-w-screen-2xl px-4 sm:px-6 lg:px-20">
        <div className="py-4 border-b border-white border-opacity-10">
          <div className="flex items-center justify-center flex-col lg:space-y-0 space-y-4 lg:justify-between lg:flex-row">
            <Link to="/">
              <AESIcon />
            </Link>
            <div className="flex mt-2 space-x-4 sm:justify-center sm:mt-0">
              <a
                href="https://www.linkedin.com/company/civilengineringrecruitment/"
                target="_blank"
                rel="noreferrer"
                className="w-9 h-9 rounded-full border border-gray-700 flex justify-center items-center hover:border-indigo-600"
              >
                <FaLinkedin className="w-[1rem] h-[1rem] text-white" />
              </a>
              <a
                href="https://www.facebook.com/alexandersearch01/"
                target="_blank"
                rel="noreferrer"
                className="w-9 h-9 rounded-full border border-gray-700 flex justify-center items-center hover:border-indigo-600"
              >
                <FaFacebookF className="w-[1rem] h-[1rem] text-white" />
              </a>
              <a
                href="https://mobile.twitter.com/AlexanderExecu1"
                target="_blank"
                rel="noreferrer"
                className="w-9 h-9 rounded-full border border-gray-700 flex justify-center items-center hover:border-indigo-600"
              >
                <FaXTwitter className="w-[1rem] h-[1rem] text-white" />
              </a>
              <a
                href="#"
                className="w-9 h-9 rounded-full border border-gray-700 flex justify-center items-center hover:border-indigo-600"
              >
                <FaInstagram className="w-[1rem] h-[1rem] text-white" />
              </a>
              {authContext.isAuthenticated ? (
                <>
                  <PrimaryButton onClick={authContext.logout}>
                    Logout
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    navigate("/login");
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Admin
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-8 gap-6 pt-14 pb-10 max-w-md mx-auto md:max-w-xl lg:max-w-full">
          <div className="block sm:mr-10 col-span-2 sm:col-span-1">
            <h4 className="text-2xl text-white mb-4">
              Subscribe to our <br /> newsletter
            </h4>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={SubscribeSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                alert(`Thank you for subscribing, ${values.email}`);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="flex justify-center text-white">
                  <div className="mb-4 w-full">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      className="block w-full px-4 py-4 border-b border-white border-opacity-10 bg-transparent focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm mt-2"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[#E9EAFF] hover:bg-[#E9EAFF] h-14 text-white font-bold py-4 px-5 mb-4 rounded-t focus:outline-none"
                  >
                    <FaAngleRight className="text-primary" />
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="block">
            <h4 className="text-xl text-white font-bold mb-4">About Us</h4>
            <ul className="text-base transition-all duration-500">
              <li className="mb-2">
                <div
                  onClick={() => {
                    navigate("/blogs");
                    window.scrollTo(0, 0);
                  }}
                  className="text-gray-400 cursor-pointer hover:text-white"
                >
                  Blog
                </div>
              </li>
              <li className="mb-2">
                <div
                  onClick={() => {
                    navigate("/job-board");
                    window.scrollTo(0, 0);
                  }}
                  className="text-gray-400 cursor-pointer hover:text-white"
                >
                  Job Board
                </div>
              </li>
              <li className="mb-2">
                <div
                  onClick={() => {
                    navigate("/our-process");
                    window.scrollTo(0, 0);
                  }}
                  className="text-gray-400 cursor-pointer hover:text-white"
                >
                  Our process
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  className="text-gray-400 cursor-pointer hover:text-white"
                >
                  Contact Us
                </div>
              </li>
            </ul>
          </div>
          <div className="block">
            <h4 className="text-xl text-white font-bold mb-4">UK Office</h4>
            <ul className="text-base transition-all duration-500">
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3629"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3629)">
                      <path
                        d="M12 22C10.2333 22 8.79167 21.7208 7.675 21.1625C6.55833 20.6042 6 19.8833 6 19C6 18.6 6.12083 18.2292 6.3625 17.8875C6.60417 17.5458 6.94167 17.25 7.375 17L8.95 18.475C8.8 18.5417 8.6375 18.6167 8.4625 18.7C8.2875 18.7833 8.15 18.8833 8.05 19C8.26667 19.2667 8.76667 19.5 9.55 19.7C10.3333 19.9 11.15 20 12 20C12.85 20 13.6708 19.9 14.4625 19.7C15.2542 19.5 15.7583 19.2667 15.975 19C15.8583 18.8667 15.7083 18.7583 15.525 18.675C15.3417 18.5917 15.1667 18.5167 15 18.45L16.55 16.95C17.0167 17.2167 17.375 17.5208 17.625 17.8625C17.875 18.2042 18 18.5833 18 19C18 19.8833 17.4417 20.6042 16.325 21.1625C15.2083 21.7208 13.7667 22 12 22ZM12 19C9.65 17.2667 7.89583 15.5833 6.7375 13.95C5.57917 12.3167 5 10.7167 5 9.15C5 7.96667 5.2125 6.92917 5.6375 6.0375C6.0625 5.14583 6.60833 4.4 7.275 3.8C7.94167 3.2 8.69167 2.75 9.525 2.45C10.3583 2.15 11.1833 2 12 2C12.8167 2 13.6417 2.15 14.475 2.45C15.3083 2.75 16.0583 3.2 16.725 3.8C17.3917 4.4 17.9375 5.14583 18.3625 6.0375C18.7875 6.92917 19 7.96667 19 9.15C19 10.7167 18.4208 12.3167 17.2625 13.95C16.1042 15.5833 14.35 17.2667 12 19ZM12 11C12.55 11 13.0208 10.8042 13.4125 10.4125C13.8042 10.0208 14 9.55 14 9C14 8.45 13.8042 7.97917 13.4125 7.5875C13.0208 7.19583 12.55 7 12 7C11.45 7 10.9792 7.19583 10.5875 7.5875C10.1958 7.97917 10 8.45 10 9C10 9.55 10.1958 10.0208 10.5875 10.4125C10.9792 10.8042 11.45 11 12 11Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span>60 Cannon Street, London, EC4N 6P United Kingdom</span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3635"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3635)">
                      <path
                        d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span>+44 (0)203 983 4415</span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3641"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3641)">
                      <path
                        d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L20 8V6L12 11L4 6V8L12 13Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span className="overflow-wrap-anywhere">
                    info@aesrecruitment.com
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="block">
            <h4 className="text-xl text-white font-bold mb-4">USA Office</h4>
            <ul className="text-base transition-all duration-500">
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3629"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3629)">
                      <path
                        d="M12 22C10.2333 22 8.79167 21.7208 7.675 21.1625C6.55833 20.6042 6 19.8833 6 19C6 18.6 6.12083 18.2292 6.3625 17.8875C6.60417 17.5458 6.94167 17.25 7.375 17L8.95 18.475C8.8 18.5417 8.6375 18.6167 8.4625 18.7C8.2875 18.7833 8.15 18.8833 8.05 19C8.26667 19.2667 8.76667 19.5 9.55 19.7C10.3333 19.9 11.15 20 12 20C12.85 20 13.6708 19.9 14.4625 19.7C15.2542 19.5 15.7583 19.2667 15.975 19C15.8583 18.8667 15.7083 18.7583 15.525 18.675C15.3417 18.5917 15.1667 18.5167 15 18.45L16.55 16.95C17.0167 17.2167 17.375 17.5208 17.625 17.8625C17.875 18.2042 18 18.5833 18 19C18 19.8833 17.4417 20.6042 16.325 21.1625C15.2083 21.7208 13.7667 22 12 22ZM12 19C9.65 17.2667 7.89583 15.5833 6.7375 13.95C5.57917 12.3167 5 10.7167 5 9.15C5 7.96667 5.2125 6.92917 5.6375 6.0375C6.0625 5.14583 6.60833 4.4 7.275 3.8C7.94167 3.2 8.69167 2.75 9.525 2.45C10.3583 2.15 11.1833 2 12 2C12.8167 2 13.6417 2.15 14.475 2.45C15.3083 2.75 16.0583 3.2 16.725 3.8C17.3917 4.4 17.9375 5.14583 18.3625 6.0375C18.7875 6.92917 19 7.96667 19 9.15C19 10.7167 18.4208 12.3167 17.2625 13.95C16.1042 15.5833 14.35 17.2667 12 19ZM12 11C12.55 11 13.0208 10.8042 13.4125 10.4125C13.8042 10.0208 14 9.55 14 9C14 8.45 13.8042 7.97917 13.4125 7.5875C13.0208 7.19583 12.55 7 12 7C11.45 7 10.9792 7.19583 10.5875 7.5875C10.1958 7.97917 10 8.45 10 9C10 9.55 10.1958 10.0208 10.5875 10.4125C10.9792 10.8042 11.45 11 12 11Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span>
                    641 Lexington Avenue New York, NY, 10022, United States
                  </span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3635"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3635)">
                      <path
                        d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span>+001(332) 600 8926</span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="flex gap-2 text-gray-400 hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_9_3641"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9_3641)">
                      <path
                        d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 13L20 8V6L12 11L4 6V8L12 13Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span className="overflow-wrap-anywhere">
                    info@aesrecruitment.com
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#231E54] h-10 w-full skew-y-2 -mb-6"></div>
      <div className="py-4 border-t border-white flex flex-col items-center justify-center border-opacity-10 bg-[#231E54] w-full px-4 sm:px-6 lg:px-20">
        <div className="flex text-white max-w-screen-2xl z-10 items-center justify-between w-full mx-20 flex-col lg:space-y-0 space-y-8 lg:justify-between lg:flex-row">
          <span className="block">
            © <a href="#">AES</a> 2024, All rights reserved.
          </span>
          <div className="flex gap-4">
            <span className="block cursor-pointer">Terms of use</span>
            <span
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/privacy");
              }}
              className="block cursor-pointer"
            >
              Privacy
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const AESIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="249"
    height="41"
    viewBox="0 0 249 41"
    fill="none"
  >
    <path d="M244.232 36.4089H78.8203V37.0665H244.232V36.4089Z" fill="white" />
    <path d="M244.232 5.20679H78.8203V5.86437H244.232V5.20679Z" fill="white" />
    <path
      d="M8.78455 36.409L5.1143 31.1424C4.68108 30.5237 4.65401 29.7077 5.0451 29.0621L20.1563 4.01733H28.5197L35.9896 16.867H43.1917L39.5576 23.2485H31.6424L24.1936 10.1537L8.78455 36.409Z"
      fill="white"
    />
    <path
      d="M16.5674 29.8958L23.9801 16.825L28.1197 23.604L23.9801 30.2724H43.5498V34.5915L47.4728 36.1876H20.8724L16.5674 29.8958Z"
      fill="white"
    />
    <path
      d="M32.4216 4.01733L35.9896 10.6649H43.5046C43.5046 10.6649 44.0612 6.19033 48.5437 4.01733H32.4216Z"
      fill="white"
    />
    <path
      d="M44.8313 26.064V33.677C44.8313 33.677 49.4281 36.3222 56.7024 36.3222C63.9768 36.3222 69.6897 33.1689 69.6897 26.9189C69.5935 14.8613 51.0316 19.5211 50.5322 11.5883C50.5322 8.13905 54.0851 7.34397 57.0574 7.34397C60.0297 7.34397 64.3227 8.83249 64.3227 13.5133H67.9148V6.63259C67.9148 6.63259 64.2024 3.93652 56.558 3.93652C48.9137 3.93652 44.8282 7.88199 44.8282 12.76C44.4221 24.163 63.2638 20.4328 63.5827 27.7707C63.5827 29.9318 62.9028 32.846 56.3775 32.846C49.8523 32.846 48.4534 28.9394 48.4534 26.1447L44.8282 26.064H44.8313Z"
      fill="white"
    />
    <path
      d="M92.0122 20.7106H84.3738L82.9178 24.2137H79.0159L86.3504 7.86694H90.1109L97.4664 24.2137H93.4713L92.0152 20.7106H92.0122ZM90.8148 17.8382L88.2066 11.5793L85.5983 17.8382H90.8148Z"
      fill="white"
    />
    <path
      d="M100.686 7.86694H104.494V21.1321H112.743V24.2137H100.686V7.86694Z"
      fill="white"
    />
    <path
      d="M129.121 21.1769V24.2137H116.383V7.86694H128.817V10.9038H120.168V14.4547H127.806V17.3959H120.168V21.1799H129.124L129.121 21.1769Z"
      fill="white"
    />
    <path
      d="M143.856 24.2137L139.885 18.5406L135.983 24.2137H131.612L137.698 15.9014L131.916 7.86694H136.242L140.026 13.1664L143.739 7.86694H147.851L142.117 15.7609L148.251 24.2137H143.856Z"
      fill="white"
    />
    <path
      d="M161.765 20.7106H154.127L152.671 24.2137H148.769L156.103 7.86694H159.864L167.219 24.2137H163.224L161.768 20.7106H161.765ZM160.565 17.8382L157.956 11.5793L155.348 17.8382H160.565Z"
      fill="white"
    />
    <path
      d="M185.526 7.86694V24.2137H182.4L174.196 14.2903V24.2137H170.435V7.86694H173.585L181.765 17.7904V7.86694H185.526Z"
      fill="white"
    />
    <path
      d="M190.929 7.86694H198.402C203.784 7.86694 207.475 11.0891 207.475 16.0388C207.475 20.9886 203.784 24.2108 198.402 24.2108H190.929V7.86694ZM198.215 21.1082C201.482 21.1082 203.621 19.1713 203.621 16.0418C203.621 12.9124 201.482 10.9755 198.215 10.9755H194.737V21.1112H198.215V21.1082Z"
      fill="white"
    />
    <path
      d="M224.584 21.1769V24.2137H211.846V7.86694H224.28V10.9038H215.631V14.4547H223.269V17.3959H215.631V21.1799H224.587L224.584 21.1769Z"
      fill="white"
    />
    <path
      d="M239.602 24.2137L236.428 19.6615H232.926V24.2137H229.117V7.86694H236.238C240.634 7.86694 243.383 10.1326 243.383 13.7971C243.383 16.2481 242.138 18.0475 239.999 18.9591L243.69 24.2137H239.602ZM236.028 10.9486H232.926V16.6456H236.028C238.353 16.6456 239.53 15.5726 239.53 13.7971C239.53 12.0216 238.353 10.9486 236.028 10.9486Z"
      fill="white"
    />
    <path
      d="M84.2836 32.8401V34.1433H78.8203V27.1311H84.1542V28.4343H80.4449V29.9557H83.721V31.2171H80.4449V32.8401H84.2866H84.2836Z"
      fill="white"
    />
    <path
      d="M90.5622 34.1433L88.8594 31.7102L87.1867 34.1433H85.3125L87.9238 30.5774L85.4449 27.1311H87.3011L88.9256 29.4057L90.5171 27.1311H92.28L89.8191 30.5176L92.4515 34.1433H90.5652H90.5622Z"
      fill="white"
    />
    <path
      d="M99.1603 32.8401V34.1433H93.697V27.1311H99.0309V28.4343H95.3216V29.9557H98.5977V31.2171H95.3216V32.8401H99.1633H99.1603Z"
      fill="white"
    />
    <path
      d="M100.601 30.6371C100.601 28.5239 102.235 27.0115 104.431 27.0115C105.649 27.0115 106.669 27.4509 107.334 28.2519L106.287 29.2144C105.812 28.6734 105.219 28.3924 104.512 28.3924C103.191 28.3924 102.253 29.313 102.253 30.6371C102.253 31.9612 103.191 32.8819 104.512 32.8819C105.219 32.8819 105.812 32.6009 106.287 32.0509L107.334 33.0134C106.669 33.8234 105.649 34.2658 104.422 34.2658C102.235 34.2658 100.601 32.7533 100.601 30.6401V30.6371Z"
      fill="white"
    />
    <path
      d="M108.919 31.0586V27.1311H110.553V30.9959C110.553 32.329 111.137 32.8789 112.126 32.8789C113.116 32.8789 113.7 32.329 113.7 30.9959V27.1311H115.312V31.0586C115.312 33.121 114.124 34.2628 112.117 34.2628C110.111 34.2628 108.922 33.121 108.922 31.0586H108.919Z"
      fill="white"
    />
    <path
      d="M118.868 28.4522H116.609V27.1311H122.758V28.4522H120.499V34.1403H118.865V28.4522H118.868Z"
      fill="white"
    />
    <path d="M124.241 27.1311H125.875V34.1433H124.241V27.1311Z" fill="white" />
    <path
      d="M134.894 27.1311L131.841 34.1433H130.228L127.184 27.1311H128.947L131.095 32.1377L133.273 27.1311H134.897H134.894Z"
      fill="white"
    />
    <path
      d="M141.549 32.8401V34.1433H136.085V27.1311H141.419V28.4343H137.71V29.9557H140.986V31.2171H137.71V32.8401H141.552H141.549Z"
      fill="white"
    />
    <path
      d="M146.356 33.4498L146.91 32.2273C147.505 32.6577 148.393 32.9596 149.238 32.9596C150.207 32.9596 150.598 32.6398 150.598 32.2094C150.598 30.8972 146.495 31.7999 146.495 29.1935C146.495 28.0009 147.463 27.0085 149.467 27.0085C150.354 27.0085 151.26 27.2178 151.916 27.6303L151.41 28.8617C150.754 28.4911 150.081 28.3117 149.455 28.3117C148.486 28.3117 148.113 28.6734 148.113 29.1128C148.113 30.404 152.216 29.5133 152.216 32.0868C152.216 33.2585 151.239 34.2598 149.223 34.2598C148.104 34.2598 146.976 33.9281 146.35 33.4498H146.356Z"
      fill="white"
    />
    <path
      d="M159.41 32.8401V34.1433H153.946V27.1311H159.28V28.4343H155.571V29.9557H158.847V31.2171H155.571V32.8401H159.413H159.41Z"
      fill="white"
    />
    <path
      d="M165.962 32.6398H162.686L162.06 34.1433H160.387L163.534 27.1311H165.147L168.302 34.1433H166.588L165.962 32.6398ZM165.447 31.4084L164.328 28.7242L163.209 31.4084H165.447Z"
      fill="white"
    />
    <path
      d="M174.136 34.1433L172.776 32.1915H171.275V34.1433H169.641V27.1311H172.695C174.581 27.1311 175.76 28.1025 175.76 29.6747C175.76 30.7269 175.225 31.498 174.307 31.8896L175.89 34.1433H174.136ZM172.605 28.4522H171.275V30.8972H172.605C173.603 30.8972 174.106 30.4369 174.106 29.6747C174.106 28.9125 173.6 28.4522 172.605 28.4522Z"
      fill="white"
    />
    <path
      d="M177.21 30.6371C177.21 28.5239 178.844 27.0115 181.04 27.0115C182.258 27.0115 183.278 27.4509 183.943 28.2519L182.896 29.2144C182.421 28.6734 181.828 28.3924 181.121 28.3924C179.801 28.3924 178.862 29.313 178.862 30.6371C178.862 31.9612 179.801 32.8819 181.121 32.8819C181.828 32.8819 182.421 32.6009 182.896 32.0509L183.943 33.0134C183.278 33.8234 182.258 34.2658 181.031 34.2658C178.844 34.2658 177.21 32.7533 177.21 30.6401V30.6371Z"
      fill="white"
    />
    <path
      d="M192.117 27.1311V34.1433H190.483V31.2679H187.277V34.1433H185.643V27.1311H187.277V29.8959H190.483V27.1311H192.117Z"
      fill="white"
    />
    <path
      d="M197.854 27.1312H199.488V32.8192H203.025V34.1403H197.854V27.1282V27.1312Z"
      fill="white"
    />
    <path d="M204.548 27.1311H206.181V34.1433H204.548V27.1311Z" fill="white" />
    <path
      d="M214.879 34.1433L214.87 29.9378L212.794 33.402H212.057L209.99 30.0274V34.1433H208.459V27.1311H209.81L212.451 31.4891L215.053 27.1311H216.395L216.416 34.1433H214.885H214.879Z"
      fill="white"
    />
    <path d="M218.687 27.1311H220.321V34.1433H218.687V27.1311Z" fill="white" />
    <path
      d="M224.06 28.4522H221.801V27.1311H227.95V28.4522H225.691V34.1403H224.057V28.4522H224.06Z"
      fill="white"
    />
    <path
      d="M234.897 32.8401V34.1433H229.433V27.1311H234.767V28.4343H231.058V29.9557H234.334V31.2171H231.058V32.8401H234.9H234.897Z"
      fill="white"
    />
    <path
      d="M236.801 27.1311H240.008C242.315 27.1311 243.898 28.512 243.898 30.6372C243.898 32.7624 242.315 34.1433 240.008 34.1433H236.801V27.1311ZM239.927 32.8102C241.329 32.8102 242.246 31.9792 242.246 30.6372C242.246 29.2951 241.329 28.4642 239.927 28.4642H238.434V32.8102H239.927Z"
      fill="white"
    />
  </svg>
);
