import React, { useContext, useEffect, useState } from "react";
import JobForm from "./JobForm";
import { JobType } from "../../types/types";
import Jobs from "../common/Jobs";
import {
  AuthContext,
  AuthContextType,
  checkAuthStatus,
} from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const CreateJob = () => {
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [editJobData, setEditJobData] = useState({} as JobType);

  const navigate = useNavigate();

  const checkStatus = async () => {
    const status = await checkAuthStatus();
    if (!status.status) {
      navigate("/login");
    }
  };

  useEffect(() => {
    (async () => {
      await checkStatus();
    })();
  }, []);

  const authContext = useContext(AuthContext) as AuthContextType;

  useEffect(() => {}, [mode, authContext]);

  if (authContext === undefined) {
    return (
      <div className="h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (!authContext.isAuthenticated) {
    return (
      <div className="h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center w-full justify-center">
      {mode === "edit" ? (
        <JobForm
          mode="edit"
          initialValues={editJobData}
          jobId={editJobData._id}
        />
      ) : (
        <JobForm mode="create" />
      )}
      <div className="w-full mx-auto max-w-screen-lg">
        <Jobs
          jobs={authContext.jobs}
          setMode={setMode}
          setEditJobData={setEditJobData}
          isEditable
          setJobs={authContext.setJobs}
        />
      </div>
    </div>
  );
};

export default CreateJob;
