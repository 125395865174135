import React from "react";
import { BlogType } from "../../types/types";
import Blog from "./Blog";

interface BlogListProps {
  blogs: BlogType[];
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
  setBlogs?: React.Dispatch<React.SetStateAction<BlogType[]>>;
}

const BlogList = ({
  blogs,
  isEditable,
  setMode,
  setEditBlogData,
  setBlogs,
}: BlogListProps) => {
  return (
    <div>
      <h2 className="mb-4 ml-2 text-3xl font-bold">All blog posts</h2>
      <div className="grid grid-cols-1 gap-4 mb-2 md:grid-cols-2 lg:grid-cols-3 sm:mb-4">
        {blogs.map((blog) => (
          <Blog
            isEditable={isEditable}
            setMode={setMode}
            setEditBlogData={setEditBlogData}
            setBlogs={setBlogs}
            key={blog._id}
            blog={blog}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
