import React from "react";
import Hero from "./Hero";
import Section2 from "./Section2";
import WhyChooseUs from "./WhyChooseUs";
import { Helmet } from "react-helmet";

const EngineeringPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Engineering Recruitment Experts | Alexander Executive Search
        </title>
        <meta
          name="description"
          content="Partner with Alexander Executive Search for specialized engineering recruitment services. We connect you with top engineering talent across civil, mechanical, electrical, and project engineering fields, ensuring skill alignment and high retention for your team."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <Section2 />
        <WhyChooseUs />
      </div>
    </>
  );
};

export default EngineeringPage;
