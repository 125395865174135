import React, { useEffect, useState } from "react";
import PrimaryButton from "../common/PrimaryButton";
import { JobType } from "../../types/types";
import { getJobs } from "../../service/service";
import { toast } from "react-toastify";
import Jobs from "../common/Jobs";

const JobFilter = () => {
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState<
    "Full Time" | "Part Time" | "Freelance" | "Internship"
  >("Full Time");
  const [remoteOnly, setRemoteOnly] = useState(false);
  const [allJobs, setAllJobs] = useState<JobType[]>([]);
  const [jobs, setJobs] = useState<JobType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch all jobs when the component mounts
    const fetchAllJobs = async () => {
      setLoading(true);
      try {
        const response = await getJobs();
        if (response.status === false) {
          toast.error(response.response?.data.message);
        } else {
          const jobs = response.response?.data as JobType[];
          const filteredJobs = jobs
            .filter((job) => !job.acceptingOpenings)
            .filter((job) => new Date(job.listingExpiryDate) > new Date());

          setJobs(filteredJobs);
          setAllJobs(filteredJobs);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllJobs();
  }, []);

  useEffect(() => {
    // filter all jobs based on acceptingOpenning
  }, []);

  const jobTypes: ("Full Time" | "Part Time" | "Freelance" | "Internship")[] = [
    "Full Time",
    "Freelance",
    "Internship",
    "Part Time",
  ];

  const handleJobTypeClick = (
    jobType: "Full Time" | "Part Time" | "Freelance" | "Internship",
  ) => {
    setSelectedJobType(jobType);
  };

  // Assuming the tags are included in a field called "companyTagline" in each job
  const filterJobs = (
    jobs: JobType[],
    keyword: string,
    location: string,
    tags: string[],
    selectedJobType: "Full Time" | "Part Time" | "Freelance" | "Internship",
    remoteOnly: boolean,
  ): JobType[] => {
    if (
      keyword === "" &&
      location === "" &&
      tags.length === 0 &&
      selectedJobType === "Full Time" &&
      remoteOnly === false
    ) {
      return allJobs;
    }

    return jobs.filter((job) => {
      // Filter by keyword (applied to jobPosition and companyName)
      const matchesKeyword = keyword
        ? job.jobPosition.toLowerCase().includes(keyword.toLowerCase()) ||
        job.companyName.toLowerCase().includes(keyword.toLowerCase())
        : true;

      // Filter by location
      const matchesLocation = location
        ? job.location.toLowerCase().includes(location.toLowerCase())
        : true;

      // Filter by tags (applied to companyTagline)
      const matchesTags =
        tags.length > 0
          ? tags.every((tag) =>
            job.companyTagline.toLowerCase().includes(tag.toLowerCase()),
          )
          : true;

      // Filter by job type
      const matchesJobType = selectedJobType
        ? job.jobType === selectedJobType
        : true;

      // Filter by remote-only (assuming "Remote" is part of the location string)
      const matchesRemote = remoteOnly
        ? job.location.toLowerCase() === "remote"
        : true;

      return (
        matchesKeyword &&
        matchesLocation &&
        matchesTags &&
        matchesJobType &&
        matchesRemote
      );
    });
  };

  const handleSearch = async () => {
    const filtered = filterJobs(
      jobs,
      keyword,
      location,
      [],
      selectedJobType,
      remoteOnly,
    );

    if (filtered.length === 0) {
      toast.warning("No jobs found");
      setJobs(filtered);
    }

    if (filtered.length > 0) {
      setJobs(filtered);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-full flex-col space-y-4 px-4 sm:px-10 py-4 sm:py-10">
      <div className="w-full flex flex-col sm:flex-row gap-4 sm:gap-8 mb-6">
        {/* Keyword Search */}
        <input
          type="text"
          placeholder="Keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          className="border rounded-md w-full p-2 sm:px-2 sm:py-0"
        />

        {/* Location Search */}
        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="border rounded-md w-full p-2 sm:px-2 sm:py-0"
        />

        {/* Search Jobs Button */}
        <PrimaryButton onClick={handleSearch} className="sm:w-[25%]">
          Search Jobs
        </PrimaryButton>
      </div>
      <hr className="w-full border px-20 border-primary opacity-50" />
      {/* Remote Only Checkbox */}
      <div className="flex items-center space-x-2 mt-4">
        <input
          type="checkbox"
          id="remoteOnly"
          checked={remoteOnly}
          onChange={() => setRemoteOnly(!remoteOnly)}
        />
        <label htmlFor="remoteOnly">Remote position only</label>
      </div>

      {/* Job Type Tags */}
      <div className="flex flex-wrap gap-2 space-x-4">
        {jobTypes.map((jobType) => (
          <button
            key={jobType}
            className={`px-3 py-1 rounded-full ${selectedJobType === jobType
                ? "bg-primary text-white"
                : "bg-transparent text-black"
              }`}
            onClick={() => handleJobTypeClick(jobType)}
          >
            {jobType}
          </button>
        ))}
      </div>

      {/* Filtered Jobs */}
      <div className="mt-4 sm:!mt-10">
        {jobs.length > 0 ? (
          <Jobs jobs={jobs} />
        ) : (
          <p className="text-gray-500">No jobs match your criteria.</p>
        )}
      </div>
    </div>
  );
};

export default JobFilter;
