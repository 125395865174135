import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikField from '../common/Field'; // Assuming this is the path
import axios from "axios";
import PrimaryButton from '../common/PrimaryButton';
import { toast } from 'react-toastify';
import FormikFieldSelect from '../common/FormikFieldSelect';
import { Grid } from 'react-loader-spinner';
import { AuthContext } from '../../context/AuthContext';
import { Editor } from 'primereact/editor';

interface JobFormValues {
  jobPosition: string;
  jobType: "Full Time" | "Part Time" | "Freelance" | "Internship";
  applicationEmailOrUrl: string;
  location: string;
  companyWebsite: string;
  companyName: string;
  companyLinkedIn: string;
  companyTagline: string;
  listingExpiryDate: Date;
  acceptingOpenings: boolean;
  companyImage: File | null;
  salaryRange: {
    min: number;
    max: number;
  }
  content: string;
  currency: "INR" | "USD" | "EUR" | "GBP" | "HKD";
}

interface JobFormProps {
  mode: 'create' | 'edit';
  initialValues?: JobFormValues; // For edit mode, we pass in the initial values
  jobId?: string; // For fetching the job when editing
}

const JobForm = ({ mode, initialValues, jobId }: JobFormProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const authContext = useContext(AuthContext);

  // Default initial values for creating a job
  const defaultValues: JobFormValues = {
    currency: "USD",
    salaryRange: { min: 0, max: 0 },
    jobPosition: '',
    jobType: 'Full Time',
    applicationEmailOrUrl: '',
    location: '',
    companyWebsite: '',
    companyName: '',
    companyLinkedIn: '',
    companyTagline: '',
    listingExpiryDate: new Date(),
    acceptingOpenings: false,
    companyImage: null,
    content: "",
  };

  const validationSchema = Yup.object({
    salaryRange: Yup.object().shape({
      min: Yup.number().required('Required'),
      max: Yup.number().required('Required'),
    }),
    currency: Yup.string().oneOf(['INR', 'USD', 'EUR', 'GBP', "HKD"]).required('Required'),
    jobType: Yup.string().oneOf(['Full Time', 'Part Time', 'Freelance', 'Internship']).required('Required'),
    jobPosition: Yup.string().required('Required'),
    applicationEmailOrUrl: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
    companyWebsite: Yup.string().url('Invalid URL').required('Required'),
    companyName: Yup.string().required('Required'),
    companyLinkedIn: Yup.string().url('Invalid URL').required('Required'),
    companyTagline: Yup.string().required('Required'),
    listingExpiryDate: Yup.date().required('Required'),
    companyImage: Yup.mixed().required('Required'),
    content: Yup.string().required('Required'),
  });

  const handleSubmit = async (values: JobFormValues) => {
    setIsSubmitting(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof JobFormValues];

      if (key === 'companyImage') {
        if (value) {
          formData.append(key, value as File);
        }
      } else if (key === 'salaryRange') {
        const salaryRange = value as { min: number; max: number };
        formData.append('salaryRange[min]', salaryRange.min.toString());
        formData.append('salaryRange[max]', salaryRange.max.toString());
      } else if (typeof value === 'boolean') {
        formData.append(key, value.toString());
      } else if (value !== null) {
        formData.append(key, value as string);
      }
    });

    let urls = []

    if (process.env.NODE_ENV === "development") {
      urls = ["http://localhost:3030/api/jobs/create", `http://localhost:3030/api/jobs/update/${jobId}`]
    }
    else {
      urls = ["https://www.aesrecruitment.com/api/jobs/create", `https://www.aesrecruitment.com/api/jobs/update/${jobId}`]
    }

    try {
      const url = mode === 'create'
        ? urls[0]
        : urls[1];

      console.log(url);
      // const url = mode === 'create'
      //   ? "http://localhost:3030/api/jobs/create"
      //   : `http://localhost:3030/api/jobs/update/${jobId}`;

      const response = await axios.post(url, formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response?.status === 500) {
        toast.error("Error while processing job");
        return;
      }

      if (response.status === 201) {
        authContext?.setJobs([response.data.job, ...authContext.jobs]);
      }

      if (response?.status === 201 || response?.status === 200) {
        toast.success(`Job ${mode === 'create' ? 'created' : 'updated'} successfully`);
      }

      setIsSubmitting(false);

    } catch (error) {
      setIsSubmitting(false);
      toast.error('An unexpected error occurred.');
    }
  };

  if (authContext === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col mb-10 sm:mb-20 overflow-hidden mx-auto max-w-screen-2xl items-center justify-center mt-32">
      <div className="w-full">
        <h1 className="text-lg sm:text-xl mx-4 inter-bold mb-4">{isSubmitting ? 'Submitting...' : mode === 'create' ? 'Post your job here' : 'Edit your job'}</h1>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues || defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form encType="multipart/form-data" className="max-w-6xl mx-4">
            {
              isSubmitting ?
                <div className='flex w-full h-[35rem] justify-center items-center'>
                  <Grid
                    visible={true}
                    height="80"
                    width="80"
                    color="#2E388F"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperClass="grid-wrapper"
                  />
                </div>
                : <>
                  <div className='flex flex-col sm:flex-row gap-4 w-full'>
                    <FormikField
                      name="jobPosition"
                      label="Job Position"
                      placeholder="Enter job position"
                      type="text"
                    />
                    <FormikFieldSelect
                      name="jobType"
                      label="Job Type"
                      options={[
                        { label: 'Full Time', value: 'Full Time' },
                        { label: 'Part Time', value: 'Part Time' },
                        { label: 'Freelance', value: 'Freelance' },
                        { label: 'Internship', value: 'Internship' },
                      ]}
                    />
                  </div>
                  <div className='flex flex-col sm:flex-row gap-4 w-full'>
                    <FormikField
                      name="applicationEmailOrUrl"
                      label="Application Email/ URL"
                      placeholder="Enter application email or URL"
                    />
                    <FormikField
                      name="location"
                      label="Location"
                      placeholder="Enter location"
                    />
                  </div>

                  <div className='flex flex-col sm:flex-row gap-4 w-full'>
                    <FormikField
                      name="companyWebsite"
                      label="Company Website"
                      placeholder="Enter company website"
                      type="url"
                    />

                    <FormikField
                      name="companyName"
                      label="Company Name"
                      placeholder="Enter company name"
                    />
                  </div>
                  <div className='flex flex-col sm:flex-row gap-4 w-full'>
                    <FormikField
                      name="companyLinkedIn"
                      label="Company LinkedIn"
                      placeholder="Enter LinkedIn profile URL"
                      type="url"
                    />
                    <FormikField
                      name="companyTagline"
                      label="Company Tagline"
                      placeholder="Enter company tagline"
                    />
                  </div>

                  <div className='flex flex-col sm:flex-row gap-4 w-full'>
                    <div className='w-full'>
                      <FormikField
                        required
                        name="listingExpiryDate"
                        label="Listing Expiry Date"
                        placeholder="Select expiry date"
                        type="date"
                      />
                      <div className='flex flex-col sm:flex-row gap-2 w-full'>
                        <FormikFieldSelect
                          className='sm:!w-[80px]'
                          name="currency"
                          label="Currency"
                          options={[
                            { label: 'INR', value: 'INR' },
                            { label: 'USD', value: 'USD' },
                            { label: 'EUR', value: 'EUR' },
                            { label: "GBP", value: "GBP" },
                            { label: "HKD", value: "HKD" },
                          ]}
                        />

                        <FormikField
                          name="salaryRange.min"
                          className="sm:!w-[150px]"
                          label="Minimum Salary"
                          placeholder="Enter salary"
                          type="number"
                        />
                        <FormikField
                          name="salaryRange.max"
                          className="sm:!w-[150px]"
                          label="Maximum Salary"
                          placeholder="Enter salary"
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="mb-10 w-full">
                      <label className="block mb-1 text-sm font-medium text-gray-700" htmlFor="companyImage">
                        Company Image
                      </label>

                      <input
                        required
                        id="companyImage"
                        name="companyImage"
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            setFieldValue('companyImage', event.currentTarget.files[0]);
                          }
                        }}
                        className="flex w-full h-full px-3 py-2 border rounded-lg bg-white border-gray-300 shadow-sm poppins-regular focus:ring-0 active:ring-0 focus:outline-none"
                      />

                    </div>

                  </div>
                  <div className="flex w-full mb-4">
                    <Field
                      name="acceptingOpenings"
                      type="checkbox"
                      className="w-auto"
                    />
                    <label
                      htmlFor="acceptingOpenings"
                      className="block ml-2 text-sm font-medium text-gray-700"
                    >
                      Filled listings will no longer accept applications.
                    </label>
                  </div>

                  <label
                    className="block mb-1 text-sm font-medium text-gray-700"
                    htmlFor="content"
                  >
                    Content
                  </label>

                  <Editor
                    className="h-[10rem] mb-20 sm:mb-16 max-w-4xl"
                    onTextChange={(text) => {
                      setFieldValue("content", text.htmlValue);
                    }}
                  />
                </>
            }
            <PrimaryButton disabled={isSubmitting} type="submit">
              {mode === 'create' ? 'Submit Job' : 'Update Job'}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JobForm;
