import React from "react";
import ourProcessImg5 from "../../assets/images/our-process-img5.png";

const Understanding = () => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center background-linear-grad max-w-screen-xl gap-4 sm:gap-6 xl:gap-0 my-10">
      <div data-aos="fade-right" className="sm:w-[80%]">
        <img src={ourProcessImg5} alt="engg" />
      </div>
      <div className="sm:w-[90%] sm:p-6 mx-4">
        <h2
          data-aos="fade-left"
          className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-primary"
        >
          Understanding and Assessing Behaviors in Hiring
        </h2>
        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          AES® is our trusted recruitment approach, powered by the latest
          executive search technology and hosted on our award-winning, fully
          digital platform. This proven methodology enables us to deliver
          outstanding results in a streamlined, highly effective recruitment
          proces Job descriptions are often packed with skill and knowledge
          requirements, using broad terms like “motivated, self-starting,
          team-player.” But when a hire doesn’t work out, it’s rarely due to a
          lack of skills—rather, it’s often a result of a behavioral mismatch
          with the company’s culture.
        </p>
        <p
          data-aos="fade-left"
          data-aos-delay="100"
          className="text-sm sm:text-lg inter-regular mb-2 leading-5"
        >
          This disconnect is why organizations typically see a 30% failure rate
          in retaining new hires. In other words, only 7 out of 10 new hires
          stay beyond their first year.
        </p>
      </div>
    </div>
  );
};

export default Understanding;
