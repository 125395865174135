import React from 'react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <div className='w-full flex justify-center items-center my-6 sm:my-20'>
      <div className='max-w-screen-2xl w-full flex flex-col sm:flex-row gap-4 sm:gap-10 justify-center mx-4 sm:mx-20'>
        <div data-aos="fade-right">
          <svg xmlns="http://www.w3.org/2000/svg" width="248" height="124" viewBox="0 0 248 124" fill="none">
            <path d="M15.3319 124L1.31653 103.889C-0.337746 101.526 -0.441137 98.41 1.05231 95.9446L58.7567 0.307617H90.6935L119.218 49.3759H146.721L132.843 73.7446H102.618L74.1737 23.7403L15.3319 124Z" fill="#231E54" />
            <path d="M45.0518 99.1302L73.3583 49.2173L89.1658 75.104L73.3583 100.568H148.088V117.061L163.068 123.156H61.4911L45.0518 99.1302Z" fill="#2E388F" />
            <path d="M105.593 0.307617L119.218 25.6921H147.915C147.915 25.6921 150.04 8.60552 167.157 0.307617H105.593Z" fill="#2E388F" />
            <path d="M152.981 84.497V113.568C152.981 113.568 170.535 123.67 198.313 123.67C226.091 123.67 247.907 111.628 247.907 87.7614C247.539 41.7178 176.658 59.512 174.751 29.2196C174.751 16.0479 188.318 13.0118 199.669 13.0118C211.019 13.0118 227.412 18.696 227.412 36.5701H241.129V10.2953C241.129 10.2953 226.953 0 197.762 0C168.571 0 152.97 15.0663 152.97 33.6938C151.419 77.2378 223.369 62.9933 224.586 91.0144C224.586 99.2666 221.99 110.395 197.072 110.395C172.155 110.395 166.813 95.4772 166.813 84.8052L152.97 84.497H152.981Z" fill="#231E54" />
          </svg>
        </div>
        <ContactForm />
      </div>

    </div>
  )
}

export default Contact
