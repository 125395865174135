import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
}

const PrimaryButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`sm:px-[50px] px-[30px] py-[14px] text-sm hover:!-translate-y-1 transition-all duration-200 text-white bg-primary rounded-md hover:shadow-none focus:outline-none focus:ring-0 disabled:opacity-50 ${className ? className : ""
        }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
