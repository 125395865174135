import React from 'react'
import JobFilter from './JobFilter'
const JobBoard = () => {

  return (
    <div className='relative my-6 w-full sm:my-20'>
      <h1 className='text-2xl sm:text-6xl text-center inter-regular-italic !font-bold text-primary'>SEARCH OUR JOB</h1>
      <JobFilter />
    </div>
  )
}

export default JobBoard
