import React from "react";
import JobBoard from "../JobBoardPage/JobBoard";
import Hero from "./Hero";
import HiringProcess from "./HiringProcess";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Solution from "./Solution";
import Survey from "./Survey";
import Understanding from "./UnderStanding";
import { Helmet } from "react-helmet";
import WhatIsAES from "./WhatIsAES";

const OurProcessPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Our Recruitment Process | Alexander Executive Search – Precision in
          Civil Engineering Recruitment
        </title>
        <meta
          name="description"
          content="Discover the Alexander Executive Search process: a structured, data-driven recruitment approach ensuring quality hires in civil engineering. Our method combines rigorous assessments with industry insights for exceptional talent matches, backed by a 12-month guarantee."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <Section2 />
        <Section3 />
        <Section4 />
        <WhatIsAES />
        <HiringProcess />
        <Understanding />
        <Solution />
        <Survey />
        <JobBoard />
      </div>
    </>
  );
};

export default OurProcessPage;
