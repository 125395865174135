import React from 'react'
import ourProcessImg2 from '../../assets/images/our-process-img2.webp'

type Card = {
  description: string;
}

const cards: Card[] = [
  {
    description: "97% 12-month employee retention rate - exceptional for any industry",
  },
  {
    description: "$100k+ savings per hire through boosted retention and less downtime",
  },
  {
    description: "Faster time-to-hire enabled by our streamlined process",
  },
  {
    description: "AES® combines proven strategies with advanced technology to modernize your recruitment experience.",
  },
]

const Section3 = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center my-6 px-4 sm:my-20 sm:mx-auto'>
      <h2 className='text-3xl sm:text-5xl text-center text-primary inter-regular'>With AES®, you get</h2>
      <img src={ourProcessImg2} alt="civil service" className="w-full mt-4 sm:mt-10 object-cover h-[300px] sm:h-auto" />
      <div className='flex flex-wrap justify-center gap-10 py-10'>
        {
          cards.map((card, index) => (
            <div key={index} className='max-w-[280px] hover:bg-primary hover:text-white rounded-md transition-all duration-300 shadow-md px-10 py-10 h-[250px]'>
              <p>{card.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Section3
