import React from "react";
import { useNavigate } from "react-router-dom";
import laptopImg from "../../assets/images/laptop.webp";
import PrimaryButton from "../common/PrimaryButton";

const Section5 = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="relative flex flex-col-reverse sm:flex-row px-4 my-6 sm:px-0 items-center justify-between w-full max-w-screen-2xl gap-4">
        <svg
          className="absolute -z-10 sm:bottom-0 bottom-72"
          xmlns="http://www.w3.org/2000/svg"
          width="auto"
          height="auto"
          viewBox="0 0 1440 527"
          fill="none"
        >
          <path
            d="M-1 527V0L1440 72.6295V527H-1Z"
            fill="url(#paint0_linear_9_3075)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_9_3075"
              x1="364.94"
              y1="-376"
              x2="521.277"
              y2="768.192"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2E3192" />
              <stop offset="0.458035" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
        <div className="sm:w-[50%] flex flex-col sm:ml-20 gap-2 sm:gap-4 justify-center">
          <h4
            data-aos="fade-right"
            className="text-xl sm:text-2xl inter-regular"
          >
            Explore our 21st Century Recruitment Process
          </h4>
          <h2
            data-aos="fade-right"
            data-aos-delay="100"
            className="text-3xl sm:text-[42px] sm:leading-[50px] inter-regular mb-4 !font-medium text-primary"
          >
            Setting a New <br /> Recruitment Standard
          </h2>
          <p
            data-aos="fade-right"
            data-aos-delay="200"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            At the core of AES is our commitment to shattering outdated hiring
            conventions through innovation. Our comprehensive online candidate
            profiles transcend one-dimensional resumes
          </p>
          <PrimaryButton
            onClick={() => {
              navigate("/contact");
              window.scrollTo(0, 0);
            }}
            data-aos="fade-right"
            data-aos-delay="300"
            className="w-[180px]"
          >
            Contact Us
          </PrimaryButton>
        </div>
        <div data-aos="fade-down" className="sm:w-[50%]">
          <img
            src={laptopImg}
            alt="engg"
            className="animate-bounce-custom transition-all duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default Section5;
