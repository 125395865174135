import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField from "../common/Field"; // Assuming this is the path
import axios from "axios";
import PrimaryButton from "../common/PrimaryButton";
import { toast } from "react-toastify";
import { Grid } from "react-loader-spinner";
import { AuthContext } from "../../context/AuthContext";
import { Editor } from "primereact/editor";

interface BlogFormValues {
  title: string;
  description: string;
  image: File | null;
  subDescription: string;
  tags: string[];
  author: string;
  content: string;
}

interface BlogFormPros {
  mode: "create" | "edit";
  initialValues?: BlogFormValues; // For edit mode, we pass in the initial values
  blogId?: string; // For fetching the blog when editing
}

const BlogForm = ({ mode, initialValues, blogId }: BlogFormPros) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tagsList, setTagsList] = useState<string[]>([]);

  // Custom function to handle comma-separated input
  const handleTagsInput = (value: string) => {
    const tagsArray = value
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);
    setTagsList(tagsArray);
  };
  const authContext = useContext(AuthContext);

  // Default initial values for creating a job
  const defaultValues: BlogFormValues = {
    title: "",
    description: "",
    image: null,
    subDescription: "",
    tags: [],
    author: "",
    content: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    subDescription: Yup.string().required("Required"),
    tags: Yup.array().required("Required"),
    image: Yup.mixed().required("Required"),
    author: Yup.string().required("Required"),
    content: Yup.string().required("Required"),
  });

  const handleSubmit = async (values: BlogFormValues) => {
    values.tags = tagsList;

    setIsSubmitting(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof BlogFormValues];

      if (key === "image") {
        if (value) {
          formData.append(key, value as File);
        }
      } else if (key === "tags") {
        const tags = tagsList as string[];
        tags.forEach((tag) => {
          formData.append("tags[]", tag);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    let urls = [];

    if (process.env.NODE_ENV === "development") {
      urls = [
        "http://localhost:3030/api/blogs/create",
        `http://localhost:3030/api/blogs/update/${blogId}`,
      ];
    } else {
      urls = [
        "https://www.aesrecruitment.com/api/blogs/create",
        `https://www.aesrecruitment.com/api/blogs/update/${blogId}`,
      ];
    }

    try {
      const url = mode === "create" ? urls[0] : urls[1];

      const response = await axios.post(url, formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response?.status === 500) {
        toast.error("Error while processing job");
        return;
      }

      if (response.status === 201) {
        authContext?.setJobs([response.data.blog, ...authContext.jobs]);
      }

      if (response?.status === 201 || response?.status === 200) {
        toast.success(
          `Blog ${mode === "create" ? "created" : "updated"} successfully`,
        );
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast.error("An unexpected error occurred.");
    }
  };

  if (authContext === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col mb-10 sm:mb-20 overflow-hidden mx-auto max-w-screen-2xl items-center justify-center mt-32">
      <div className="w-full">
        <h1 className="text-lg sm:text-xl inter-bold mx-4 mb-4">
          {isSubmitting
            ? "Submitting..."
            : mode === "create"
              ? "Create your Blog here"
              : "Edit your blog"}
        </h1>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues || defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form encType="multipart/form-data" className="max-w-6xl mx-4">
            {isSubmitting ? (
              <div className="flex w-full h-[35rem] justify-center items-center">
                <Grid
                  visible={true}
                  height="80"
                  width="80"
                  color="#2E388F"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperClass="grid-wrapper"
                />
              </div>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row gap-4 w-full">
                  <div className="w-full">
                    <FormikField
                      name="title"
                      label="Title"
                      placeholder="Add title here"
                      type="text"
                    />
                    <FormikField
                      name="subDescription"
                      label="Sub-Description"
                      placeholder="Add sub-description here"
                      type="text"
                    />
                  </div>
                  <div className=" w-full">
                    <FormikField
                      name="description"
                      label="Description"
                      placeholder="Add description here"
                      type="text"
                    />
                    <FormikField
                      name="author"
                      label="Author"
                      placeholder="Author Name"
                      type="text"
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-4 w-full">
                  <div className="w-full">
                    <label
                      htmlFor="tags"
                      className="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Tag list
                    </label>
                    <input
                      className={`w-full sm:w-[400px] px-3 py-2 border border-gray-300 inter-regular rounded-lg focus:ring-1 active:ring-0 focus:outline-none focus:ring-blue-500 `}
                      name="tags"
                      type="text"
                      placeholder="Enter tags here separated by commas"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleTagsInput(e.target.value);
                      }}
                    />
                    <div>
                      <ul className="flex flex-wrap gap-2">
                        {tagsList.map((tag, index) => (
                          <li
                            className="px-4 py-2 border border-gray-300 rounded-lg mt-2"
                            key={index}
                          >
                            {tag}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="mb-10 w-full">
                    <label
                      className="block mb-1 text-sm font-medium text-gray-700"
                      htmlFor="image"
                    >
                      Image
                    </label>
                    <input
                      required
                      id="image"
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }
                      }}
                      className="flex w-full h-full px-3 py-2 border rounded-lg bg-white border-gray-300 shadow-sm poppins-regular focus:ring-0 active:ring-0 focus:outline-none"
                    />
                  </div>
                </div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-700"
                  htmlFor="content"
                >
                  Content
                </label>

                <Editor
                  className="h-[10rem] mb-16 sm:mb-10"
                  onTextChange={(text) => {
                    setFieldValue("content", text.htmlValue);
                  }}
                />
              </>
            )}
            <PrimaryButton
              disabled={isSubmitting}
              type="submit"
              className="mt-4"
            >
              {mode === "create" ? "Submit Blog" : "Update Blog"}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BlogForm;
