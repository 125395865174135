import React, { useEffect, useState } from "react";
import mammoth from "mammoth";

const Privacy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const fetchDocx = async () => {
      try {
        // Fetch the DOCX file from the public folder
        const response = await fetch("/privacy.docx");
        if (!response.ok) {
          throw new Error("Failed to fetch the DOCX file.");
        }

        // Read the response as an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Convert DOCX ArrayBuffer to HTML using Mammoth
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setHtmlContent(result.value);

      } catch (error) {
        console.error("Error rendering DOCX:", error);
      }
    };

    fetchDocx();
  }, []);

  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
      <div className="mt-[80px] sm:mt-[90px] p-4">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
};

export default Privacy;
