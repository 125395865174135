import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PrimaryButton from '../common/PrimaryButton';
import { HomeContactFormValues } from '../../types/types';
import { sendHomeContactPageData } from '../../service/service';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const initialValues: HomeContactFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    jobTitle: '',
    email: '',
    phoneNumber: '',
    country: '',
    message: '',
    subscribe: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    jobTitle: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phoneNumber: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
  });

  const onSubmit = async (values: HomeContactFormValues) => {

    const res = await sendHomeContactPageData({ from: "home", ...values });

    if (res.status === false) {
      toast.error("Something went wrong");
    }
    else {
      toast.success("Message sent successfully");
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-8">
      <h3 className='text-xl sm:text-4xl font-light text-[#2c2c2c]'>Change starts with a</h3>
      <h1 className="text-5xl sm:text-[90px] mt-2 sm:mt-4 text-primary mb-6 sm:mb-20 font-medium uppercase">Conversation</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="grid grid-cols-2 gap-3 sm:gap-6">
          <div data-aos="fade-left">
            <label className="block sm:mb-2 text-[#00634F]" htmlFor="firstName">*First Name</label>
            <Field name="firstName" type="text" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left">
            <label className="block mb-2 text-[#00634F]" htmlFor="lastName">*Last Name</label>
            <Field name="lastName" type="text" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="100">
            <label className="block mb-2 text-[#00634F]" htmlFor="company">*Company</label>
            <Field name="company" type="text" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="company" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="100">
            <label className="block mb-2 text-[#00634F]" htmlFor="jobTitle">*Job Title</label>
            <Field name="jobTitle" type="text" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="jobTitle" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="200">
            <label className="block mb-2 text-[#00634F]" htmlFor="email">*Email Address</label>
            <Field name="email" type="email" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="200">
            <label className="block mb-2 text-[#00634F]" htmlFor="phoneNumber">*Phone Number</label>
            <Field name="phoneNumber" type="tel" className="w-full border-b focus:outline-none rounded sm:px-3 py-2" />
            <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="300">
            <label className="block mb-2 text-[#00634F]" htmlFor="country">*Country</label>
            <Field as="select" name="country" className="w-full border-b focus:outline-none rounded sm:px-3 py-2">
              <option value="" label="Select..." />
              <option value="US" label="United States" />
              <option value="UK" label="United Kingdom" />
              <option value="CA" label="Canada" />
            </Field>
            <ErrorMessage name="country" component="div" className="text-red-500 text-sm" />
          </div>
          <div data-aos="fade-left" data-aos-delay="300">
            <label className="block mb-2 text-[#00634F]" htmlFor="message">*How can we help you?</label>
            <Field as="select" name="message" className="w-full border-b focus:outline-none rounded sm:px-3 py-2">
              <option value="" label="Select..." />
              <option value="support" label="Support" />
              <option value="sales" label="Sales Inquiry" />
              <option value="feedback" label="Feedback" />
            </Field>
            <ErrorMessage name="message" component="div" className="text-red-500 text-sm" />
          </div>
          <div className="col-span-2 flex items-center mt-4" data-aos="fade-left" data-aos-delay="400">
            <Field name="subscribe" type="checkbox" className="mr-2" />
            <label className='text-sm'>
              Yes, I would like to receive regular updates on thought leadership, industry insights and upcoming events from Alexander Executive Search. I understand that I may withdraw my consent at any time. Review Alexander Executive Search Privacy Policy
            </label>
          </div>
          <div className="col-span-2 mt-3 sm:mt-6" data-aos="fade-left" data-aos-delay="500">
            <PrimaryButton type="submit">Contact Us</PrimaryButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
