import React from "react";
import Hero from "./Hero";
import Section2 from "./Section2";
import WhyChooseUs from "./WhyChooseUs";
import { Helmet } from "react-helmet";

const CivilEngineeringPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Civil Engineering Recruitment Services | Alexander Executive Search
        </title>
        <meta
          name="description"
          content="Discover expert civil engineering recruitment solutions with Alexander Executive Search. We connect industry-leading firms with top civil engineering professionals, from project engineers to executive roles, ensuring skill alignment and lasting career success."
        />
      </Helmet>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <Hero />
        <Section2 />
        <WhyChooseUs />
      </div>
    </>
  );
};

export default CivilEngineeringPage;
