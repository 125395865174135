import React from "react";
import ourProcessSecBg from "../../assets/images/our-process-sec-bg.png";
import sectionDesign from "../../assets/images/section-design.webp";
import ourProcessImg7 from "../../assets/images/our-process-img7.png";

const Survey = () => {
  return (
    <div className="w-full relative z-0 my-4 flex flex-col justify-center items-center h-full">
      <img
        src={ourProcessSecBg}
        alt="survey"
        className="object-cover absolute hidden sm:flex z-0 w-full"
      />
      <div className="relative hidden sm:flex z-10 mt-10">
        <div className="flex flex-col text-white sm:flex-row justify-between items-center max-w-screen-xl gap-4 mt-10">
          <div data-aos="fade-right" className="flex justify-center w-full">
            <img src={ourProcessImg7} alt="engg" />
          </div>
          <div className="sm:w-[80%] sm:p-6">
            <h2
              data-aos="fade-left"
              className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-white"
            >
              The Solution: Behavioral Assessment
            </h2>
            <p
              data-aos="fade-left"
              data-aos-delay="100"
              className="text-sm sm:text-lg inter-regular mb-2 leading-5"
            >
              The Job Survey® allows hiring managers, stakeholders, and
              recruiters to define the essential behavioral attributes for each
              role, setting benchmarks that can help a new hire thrive. This
              ensures that beyond skills, a candidate’s behaviors align with
              what the role demands.
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="100"
              className="text-sm sm:text-lg inter-regular mb-2 leading-5"
            >
              Alongside, the Word Survey® provides an in-depth look into an
              individual’s core personality traits, assessing how they behave in
              their current role and how they compare to the established
              behavioral profile from the Job Survey®.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-primary sm:hidden cursor-pointer sm:h-[800px] max-w-[400px] [&>.img1]:hover:scale-125 [&>.img2]:hover:translate-y-10 [&>.text]:hover:translate-y-10 relative overflow-hidden">
        <img
          className="img1 object-cover w-full sm:h-[500px] relative transition duration-300 z-10"
          src={ourProcessImg7}
          alt="engg"
        />
        <img
          className="img2 -mt-28 w-full relative z-20 h-[450px] transition duration-300"
          src={sectionDesign}
          alt="engg"
        />
        <div className="text absolute z-40 top-96 text-left mx-2 text-white transition duration-300">
          <h2
            data-aos="fade-left"
            className="text-3xl sm:text-4xl inter-regular mb-2 sm:mb-4 !font-medium text-white"
          >
            The Solution: Behavioral Assessment
          </h2>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            The Job Survey® allows hiring managers, stakeholders, and
            recruiters to define the essential behavioral attributes for each
            role, setting benchmarks that can help a new hire thrive. This
            ensures that beyond skills, a candidate’s behaviors align with what
            the role demands.
          </p>
          <p
            data-aos="fade-left"
            data-aos-delay="100"
            className="text-sm sm:text-lg inter-regular mb-2 leading-5"
          >
            Alongside, the Word Survey® provides an in-depth look into an
            individual’s core personality traits, assessing how they behave in
            their current role and how they compare to the established
            behavioral profile from the Job Survey®.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Survey;
